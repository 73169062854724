import { Component, OnInit,Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../services/authService';


@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html'
})
export class ForgotPasswordComponent implements OnInit {

    private emailid : string;
    forgotpasswordForm: FormGroup;
  

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        
    ) { 
    }

    ngOnInit() {
        this.forgotpasswordForm = this.formBuilder.group({
            emailid: ['', Validators.required]
        });
  
    }
    get f() { return this.forgotpasswordForm.controls; }

 SendVerificationEmail(){
        if (this.emailid) {
          this.authService
            .Forgotpassword({emailid: this.emailid})
            .subscribe(
              res => {
                console.log(this.emailid)
              },
              err => {
                // console.log(err.error);
                err = err.error;
                if (err.success === false) {
                  console.log(err);
                }
              }
            );
        } else {
          console.log(" type emailid")
        }
      
 }

 }