import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AdminService } from '../services/adminService';

@Component({
  selector: 'app-deletediscountdialog',
  templateUrl: './deletediscountdialog.component.html',
  styleUrls: ['./deletediscountdialog.component.css']
})
export class DeletediscountdialogComponent implements OnInit {
  deletediscountForm: FormGroup;
  discountcode:string;
  percentage:string;
  codedescription:string;
  constructor(private formBuilder: FormBuilder,public dialogRef: MatDialogRef<DeletediscountdialogComponent>,
    private adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 

    }
  ngOnInit() {
    this.deletediscountForm = this.formBuilder.group({
      discountcode: ['', Validators.required],
      percentage: ['', Validators.required],
      codedescription: ['', Validators.required],
  });
  
  }





onNoClick(): void {
this.dialogRef.close();
}

delete(): void {
this.adminService
.deleteDiscount({discountcode: this.data.discountcode, percentage: this.data.percentage,codedescription:this.data.codedescription, id:this.data.id})
.subscribe(
  res => {
    window.location.reload();
  },
  err => {
    console.log(err.error);
    err = err.error;
    if (err.success === false) {
      console.log(err);
    }
  }
);

}

}
