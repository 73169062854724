import { HttpClient, HttpHeaders } from '@angular/common/http';
import { baseURL } from '../config/config';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { ProcessHTTPMsgService } from './process-httpmsg.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  loggedIn$ = new BehaviorSubject(false);
  loggedInAdmin$ = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHTTPMsgService
  ) {}

  Forgotpassword(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/admin/forgotpassword',
      data,
      httpOptions
    );
  }
  adminLogin(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/admin/login',
      data,
      httpOptions
    )
  }

  adminSignup(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/admin/signup',
      data,
      httpOptions
    );
  }

  sendLoginDetail(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/user/login',
      data,
      httpOptions
    );
  }

  reSendLoginDetail(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/user/resendotp',
      data,
      httpOptions
    );
  }

  sendSignupDetail(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/user/signup',
      data,
      httpOptions
    );
  }

  sendOTPDetail(data: any): Observable<any> {
    return this.http
      .post<any>(
        baseURL + 'api/v1/homescreen/user/validateotp',
        data,
        httpOptions
      )
      .pipe(filter((res: any) => res.success))
      .pipe(tap(() => this.loggedIn$.next(true)));
  }

  logOut(): Observable<any> {
    return this.http
      .get<any>(baseURL + 'api/v1/homescreen/user/logout', httpOptions)
      .pipe(tap(() => this.loggedIn$.next(false)));
  }

  checkLogIn(): Observable<any> {
    return (
      this.http
        .get<any>(baseURL + 'api/v1/homescreen/user/chksignin', httpOptions)
        // .pipe(filter((res: any) => res.success))
        .pipe(tap(res => this.loggedIn$.next(res.success)))
    );
  }
  checkLogInAdmin(): Observable<any> {
    return (
      this.http
        .get<any>(baseURL + 'api/v1/homescreen/admin/chksignin', httpOptions)
        // .pipe(filter((res: any) => res.success))
        .pipe(tap(res => this.loggedInAdmin$.next(res.success)))
    );
  }
}
