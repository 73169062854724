import { Component, OnInit,Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        ) { 
    }

    ngOnInit() {
    
    }
  

 }


