import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-duplicatedialog",
  templateUrl: "./duplicatedialog.component.html",
  styleUrls: ["./duplicatedialog.component.css"],
})
export class DuplicatedialogComponent implements OnInit {
  duplicatedialogForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DuplicatedialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit() {
    this.duplicatedialogForm = this.formBuilder.group({});
  }

  reset(){
    this.dialogRef.close({ data: "No" })
  }


  onNoClick(): void {
    this.dialogRef.close({ data: "Yes" });
  }
}
