import { Component, OnInit,Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../services/authService';


@Component({
  selector: 'app-admin',
  templateUrl: './adminlogin.component.html',
  styleUrls: ['./adminlogin.component.css']
})
export class AdminLoginComponent implements OnInit {
  loggedInAdmin: boolean;
  isChecked: boolean;
  loginForm: FormGroup;
  show_button: Boolean = false;
  show_eye: Boolean = false;
    loading = false;
    submitted = false;
    error = '';
    role = '';
    errorMessage: string;
    err:boolean;
    private emailid : string;
    private password : string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        
    ) { 
        // if (this.authService.userValue) { 
        //     this.router.navigate(['/']);
        // }
    }

    ngOnInit() {

      
      this.err = false;
      this.authService.loggedInAdmin$.subscribe(log => (this.loggedInAdmin = log));
      if(JSON.parse(localStorage.getItem('RememberMe')) !== null)
{
  this.emailid = localStorage.getItem('emailid');
  this.isChecked = JSON.parse(localStorage.getItem('RememberMe'));
}
        this.loginForm = this.formBuilder.group({
            emailid: ['', Validators.required],
            password: ['', Validators.required]
        });

        
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onFocus(){
      this.err = false;
    }

changed(evt) {
    this.isChecked = evt.target.checked;
  }
  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }
  adminlogout() {
    localStorage.removeItem('currentUser');
    this.router.navigate(['/logout']);
  }

  adminlogin() {
          if (this.emailid && this.password) {
            if(this.isChecked){
              localStorage.setItem('emailid', this.emailid);
              localStorage.setItem('RememberMe', JSON.stringify(this.isChecked));
            }
            this.authService
              .adminLogin({ emailid: this.emailid, password: this.password})
              .subscribe(
                res => {
                  this.loggedInAdmin = true;
                  this.role = "admin";
                  localStorage.setItem('currentUser',this.role);
                  localStorage.setItem('user',this.emailid);
                  this.router.navigate(['/admin/offers']);
                 
                },
                err => {
                  // console.log(err.error);
                  this.err = true;
                  err = err.error;
                  if (err.success === false) {
                    console.log(err)
                  }
                }
              );
          
             
              } else {
            console.log(" type emailid and password")
          }
        
    }
  

 }