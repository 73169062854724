import { baseURL } from './config/config';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';


import 'hammerjs';

import { AppComponent } from './app.component';
import { BookingsComponent } from './bookings/bookings.component';
import { ConfirmdetailsComponent } from './confirmdetails/confirmdetails.component';
import { ConfirmdialogComponent } from './confirmdialog/confirmdialog.component';
import { FeedbackdialogComponent } from './feedbackdialog/feedbackdialog.component';
import { HomeComponent } from './home/home.component';
import {AdminLoginComponent} from './adminlogin/adminlogin.component'
import { AdminSignupComponent } from './adminsignup/adminsignup.component';
import { ForgotPasswordComponent } from './forgotpassword/forgotpassword.component';
import { AdminComponent } from './admin/admin.component';
import { LogindialogComponent } from './logindialog/logindialog.component';
import { LogoutComponent } from './logout/logout.component';
import { NewbookingComponent } from './newbooking/newbooking.component';
import { PaymentComponent } from './payment/payment.component';
import { ReportsComponent } from './reports/reports.component';
import { SignupdialogComponent } from './signupdialog/signupdialog.component';
import { TermsdialogComponent } from './termsdialog/termsdialog.component';

import { AppRoutingModule } from './app-routing/app-routing.module';
import { AuthGuard } from './auth.guard';
import {RoleGuard} from './role.guard';
import { ProcessHTTPMsgService } from './services/process-httpmsg.service';
import { InvoiceService } from './services/invoiceService';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material';

import {
  MatFormFieldModule,
  MatInputModule,
  MatNativeDateModule,
  MAT_DATE_LOCALE
} from '@angular/material';
import { CitiesComponent } from './cities/cities.component';
import { OffersComponent } from './offers/offers.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { AddcitydialogComponent } from './addcitydialog/addcitydialog.component';
import { EditcitydialogComponent } from './editcitydialog/editcitydialog.component';
import { DeletecitydialogComponent } from './deletecitydialog/deletecitydialog.component';
import { DeletediscountdialogComponent } from './deletediscountdialog/deletediscountdialog.component';
import { AdddiscountdialogComponent } from './adddiscountdialog/adddiscountdialog.component';
import { EditdiscountdialogComponent } from './editdiscountdialog/editdiscountdialog.component';
import { AddcoupondialogComponent } from './addcoupondialog/addcoupondialog.component';
import { EditcoupondialogComponent } from './editcoupondialog/editcoupondialog.component';
import { DeletecoupondialogComponent } from './deletecoupondialog/deletecoupondialog.component';
import { DuplicatedialogComponent } from './duplicatedialog/duplicatedialog.component';
import { GiftCardComponent } from './pages/gift-card/gift-card.component';

@NgModule({
  declarations: [
    AppComponent,
    BookingsComponent,
    ConfirmdetailsComponent,
    ConfirmdialogComponent,
    FeedbackdialogComponent,
    AdminLoginComponent,
    AdminSignupComponent,
    ForgotPasswordComponent,
    AdminComponent,
    HomeComponent,
    LogindialogComponent,
    LogoutComponent,
    NewbookingComponent,
    PaymentComponent,
    ReportsComponent,
    SignupdialogComponent,
    TermsdialogComponent,
    CitiesComponent,
    OffersComponent,
    SidenavComponent,
    AddcitydialogComponent,
    EditcitydialogComponent,
    DeletecitydialogComponent,
    DeletediscountdialogComponent,
    AdddiscountdialogComponent,
    EditdiscountdialogComponent,
    AddcoupondialogComponent,
    EditcoupondialogComponent,
    DeletecoupondialogComponent,
    DuplicatedialogComponent,
    GiftCardComponent,
    
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    MatPaginatorModule
  ],
  providers: [
    AuthGuard,
    RoleGuard,
    InvoiceService,
    ProcessHTTPMsgService,
    { provide: 'BaseURL', useValue: baseURL },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ConfirmdialogComponent,
    FeedbackdialogComponent,
    LogindialogComponent,
    SignupdialogComponent,
    TermsdialogComponent,
    AddcitydialogComponent,
    EditcitydialogComponent,
    DeletecitydialogComponent,
    DeletediscountdialogComponent,
    AdddiscountdialogComponent,
    EditdiscountdialogComponent,
    AddcoupondialogComponent,
    EditcoupondialogComponent,
    DeletecoupondialogComponent,
    DuplicatedialogComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
