import { Component, OnInit,ViewChild,ChangeDetectorRef } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AddcitydialogComponent } from '../addcitydialog/addcitydialog.component';
import { EditcitydialogComponent } from '../editcitydialog/editcitydialog.component';
import { AdminService } from '../services/adminService';
import { DeletecitydialogComponent } from '../deletecitydialog/deletecitydialog.component';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.css']
})

export class CitiesComponent implements OnInit {
  user:string;
  index: number;
  id: string;
  citycheck:boolean;
  displayedColumns: string[] = ['city', 'hour', 'action', 'status'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(public dialog: MatDialog, private adminService: AdminService,private cdr: ChangeDetectorRef,) {
  }


  ngOnInit() {
    this.user = localStorage.getItem('user').split('@')[0];
    this.adminService.getCitiesList().subscribe(
      res => {
        if (res.success === false) {
          console.log("empty")
        } else {
          this.dataSource = new MatTableDataSource(res);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;         
        }
      },
      err => console.log(err)
    );
  }
  

  openDialog() {
    this.dialog.open(AddcitydialogComponent,
    {width: '570px',
    height: '450px',
    panelClass: 'mat-confirm-dialog'
    });
  }

  startEdit(i: number, id: string, city: string, hour: string) {
    this.id = id;
    this.index = i;
    const dialogRef = this.dialog.open(EditcitydialogComponent, {
      width: '570px',
    height: '450px',
    panelClass: 'mat-confirm-dialog',
      data: {id: id, city: city, hour: hour}
    });
  }

  deleteItem(i: number, id: string, city: string, hour: string) {
    this.index = i;
    this.id = id;
    const dialogRef = this.dialog.open(DeletecitydialogComponent, {
      width: '570px',
    height: '303px',
    panelClass: 'mat-confirm-dialog',
      data: {id: id, city: city, hour: hour}
    });
  }

  cityclick(event, id){
    console.log(id)
    this.citycheck = event.target.checked
    this.adminService
    .cityEnabled({id:id, enabled:this.citycheck})
    .subscribe(
      res => {
        window.location.reload();
      },
      err => {
        console.log(err.error);
        err = err.error;
        if (err.success === false) {
          console.log(err);
        }
      }
    );
  }

}
