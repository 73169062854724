import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AdminService } from '../services/adminService';

@Component({
  selector: 'app-editdiscountdialog',
  templateUrl: './editdiscountdialog.component.html',
  styleUrls: ['./editdiscountdialog.component.css']
})
export class EditdiscountdialogComponent implements OnInit {
  editdiscountForm: FormGroup;
  discountcode:string;
  discountpercentage:string;
  discountcodedescription:string;
  constructor(private formBuilder: FormBuilder,public dialogRef: MatDialogRef<EditdiscountdialogComponent>,
    private adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 

    }
  ngOnInit() {
 this.discountcode = this.data.discountcode;
 this.discountpercentage = this.data.percentage;
 this.discountcodedescription = this.data.codedescription
    this.editdiscountForm = this.formBuilder.group({
      discountcode: ['', Validators.required],
      discountpercentage: ['', Validators.required],
      discountcodedescription: ['', Validators.required],

  });
  
  }

  numericOnly(event): boolean { // restrict e,+,-,E characters in  input type number
    debugger
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    return true;

  }
  
  valuechangediscountcode(event) {
    this.discountcode = event.target.value
  }
  valuechangepercentage(event) {
    this.discountpercentage = event.target.value;
  }
  valuechangecodedescription(event) {
    this.discountcodedescription = event.target.value;
  }


onNoClick(): void {
this.dialogRef.close();
}
reset(){
  this.editdiscountForm.reset();
  this.discountcode = null;
  this.discountpercentage = null;
  this.discountcodedescription = null;
}

save(): void {
  console.log(typeof(this.discountcode),typeof(this.discountpercentage),typeof(this.discountcodedescription))
if (this.discountcode && this.discountpercentage && this.discountcodedescription) {
  this.adminService
    .editDiscount({discountcode: this.discountcode, percentage: this.discountpercentage,codedescription:this.discountcodedescription, id:this.data.id})
    .subscribe(
      res => {
        this.dialogRef.close();
        window.location.reload();
      },
      err => {
        console.log(err.error);
        err = err.error;
        if (err.success === false) {
          console.log(err);
        }
      }
    );
} else {
  console.log("select both")
}
}
}
