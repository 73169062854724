export class Detail {
  date: Date;
  timeSlot: string;
  name: string;
  mobile: string;
  email: string;
  address: string;
  street: string;
  landmark: string;
  pin: string;
  otherInstructions: string;
  agree: boolean;
  latitude: number;
  longitude: number;
}

export const TimeSlot = ['9 AM - 12 PM', '12 PM - 3 PM', '3 PM - 6 PM'];

export const Cities = [
  'Bengaluru',
  'Bhopal',
  'Chennai',
  'Delhi NCR',
  'Hyderabad',
  'Mumbai',
  'Pune',
  'Vadodara'
];

export const Cities72HourRule = [
  'Bhopal',
  'Chennai',
  'Hyderabad',
  'Vadodara'
];
