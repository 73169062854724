import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

declare var PDFDocument: any;
declare var blobStream: any;

@Injectable({ providedIn: 'root' })
export class InvoiceService {
  logoURL = '/assets/images/niramai-logo.png';
  signURL = '/assets/images/sign.png';

  constructor() {}

  private generateHeader(doc, logo, faFont) {
    doc.registerFont('Fontawesome', faFont);

    doc
      .image(logo, 60, 45, { width: 150 })
      .fillColor('#eb008f')
      .fontSize(10)
      .font('Fontawesome')
      .text('', 430, 65)
      .font('Helvetica')
      .text('contact@niramai.com', 200, 65, { align: 'right' })
      .font('Fontawesome')
      .text('', 450, 80)
      .font('Helvetica')
      .text('www.niramai.com', 200, 80, { align: 'right' })
      .moveDown();
  }

  private generateCustomerInfo(doc, options) {
    doc
      .fillColor('#000')
      .fontSize(10)
      .text('To,', 60, 150, {})
      .text(options.name, 60, 165, {})
      .text(options.address, 60, 180, {})
      .text(options.street + ', ' + options.landmark, 60, 195, {})
      .text(options.city + '-' + options.pincode, 60, 210, {})

      .text('Invoice #: ' + options.invoice, 200, 165, { align: 'right' })
      .text('PAN: ' + convertBase64(options.pan), 200, 180, { align: 'right' })
      .text('GSTIN: ' + convertBase64(options.gst), 200, 195, {
        align: 'right'
      })
      .text('Date: ' + new Date(options.date).toDateString(), 200, 210, {
        align: 'right'
      })
      .moveDown();
  }

  private generateInvoiceInfo(doc, options) {
    doc
      .fontSize(10)
      .text('Invoice cum Reciept for Niramai Service', 50, 270, {
        underline: true,
        align: 'center'
      })
      .moveDown();

    const table = {
      headers: ['#', 'Particulars', 'Amount (INR)'],
      rows: [
        ['1', 'Breast Health Screening at Home', options.total + ' /-'],
        ['', 'Total', options.total + ' /-'],
        ['', 'Total amount (rounded-off)', options.total_amount + ' /-']
      ]
    };

    doc.table(table, 60, 300, { width: 480 });
  }

  private generateBankInfo(doc, options) {
    doc
      .fontSize(10)
      .text('Please pay using this link:', 60, 450)
      .fillColor('#1a73e8')
      .text(options.short_url, 180, 450)
      .fillColor('#000')
      .moveDown();
  }

  private generateInvoiceInfoL(doc, options) {
    doc
      .fontSize(10)
      .text('Invoice for Niramai Service', 50, 270, {
        underline: true,
        align: 'center'
      })
      .moveDown();

    const table = {
      headers: ['#', 'Particulars', 'Amount (INR)'],
      rows: [
        ['1', 'Breast Health Screening at Home', options.total + ' /-'],
        ['', 'Total', options.total + ' /-'],
        ['', 'Total amount (rounded-off)', options.total_amount + ' /-']
      ]
    };

    doc.table(table, 60, 300, { width: 480 });
  }

  private generateSignature(doc, sign, options) {
    doc
      .fontSize(10)
      .text('Yours Sincerely', 60, 570)
      .image(sign, 60, 585, { width: 90 })
      .text(options.signatory_name, 60, 615)
      .text(options.signatory_title, 60, 630)
      .text('Niramai Health Analytix Pvt. Ltd.', 60, 645)
      .moveDown();
  }

  private generateFooter(doc) {
    doc
      .fillColor('#eb008f')
      .fontSize(10)
      .text('NIRAMAI Health Analytix Pvt Ltd', 50, 700, { align: 'center' })
      .text('Innova Pearl, No. 17, 5th Block', 50, 715, { align: 'center' })
      .text('Koramangala Industrial Layout, Bangalore - 560091.', 50, 730, {
        align: 'center'
      })
      .text('CIN# U72200KA2016PTC095020', 50, 745, { align: 'center' })
      .moveDown();
  }

  public generatePDF(options: any): Observable<any> {
    return new Observable(observer => {
      getFAData(faFont => {
        getDataUri(this.logoURL, logoDataURI => {
          getDataUri(this.signURL, signDataURI => {
            const doc = new PDFDocument({ size: 'A4', margin: 50 });
            this.generateHeader(doc, logoDataURI, faFont);
            this.generateCustomerInfo(doc, options);
            this.generateInvoiceInfo(doc, options);
            // this.generateBankInfo(doc, options);
            this.generateSignature(doc, signDataURI, options);
            this.generateFooter(doc);
            doc.end();

            const stream = doc.pipe(blobStream());
            stream.on('finish', () => observer.next(stream));
          });
        });
      });
    });
  }

  //paylater invoice
  public generatePDFPayLater(options: any): Observable<any> {
    return new Observable(observer => {
      getFAData(faFont => {
        getDataUri(this.logoURL, logoDataURI => {
          getDataUri(this.signURL, signDataURI => {
            const doc = new PDFDocument({ size: 'A4', margin: 50 });
            this.generateHeader(doc, logoDataURI, faFont);
            this.generateCustomerInfo(doc, options);
            this.generateInvoiceInfoL(doc, options);
            this.generateBankInfo(doc, options);
            this.generateSignature(doc, signDataURI, options);
            this.generateFooter(doc);
            doc.end();

            const stream = doc.pipe(blobStream());
            stream.on('finish', () => observer.next(stream));
          });
        });
      });
    });
  }
}

PDFDocument.prototype.table = function (table, arg0, arg1, arg2) {
  let startX = this.page.margins.left,
    startY = this.y;
  let options: any = {};

  if (typeof arg0 === 'number' && typeof arg1 === 'number') {
    startX = arg0;
    startY = arg1;

    if (typeof arg2 === 'object') options = arg2;
  } else if (typeof arg0 === 'object') {
    options = arg0;
  }

  const columnCount = table.headers.length;
  const columnSpacing = options.columnSpacing || 10;
  const rowSpacing = options.rowSpacing || 5;
  const usableWidth =
    options.width ||
    this.page.width - this.page.margins.left - this.page.margins.right;

  const prepareHeader = options.prepareHeader || (() => {});
  const prepareRow = options.prepareRow || (() => {});
  const computeRowHeight = row => {
    let result = 0;

    row.forEach(cell => {
      const cellHeight = this.heightOfString(cell, {
        width: columnWidth,
        align: 'left'
      });
      result = Math.max(result, cellHeight);
    });

    return result + rowSpacing;
  };

  const columnContainerWidth = usableWidth / columnCount;
  const columnWidth = columnContainerWidth - columnSpacing;
  const maxY = this.page.height - this.page.margins.bottom;

  let rowBottomY = 0;

  this.on('pageAdded', () => {
    startY = this.page.margins.top;
    rowBottomY = 0;
  });

  // Allow the user to override style for headers
  prepareHeader();

  // Check to have enough room for header and first rows
  if (startY + 3 * computeRowHeight(table.headers) > maxY) this.addPage();

  // Print all headers
  table.headers.forEach((header, i) => {
    this.text(header, startX + i * columnContainerWidth, startY, {
      width: columnWidth,
      align: 'left'
    });
  });

  // Refresh the y coordinate of the bottom of the headers row
  rowBottomY = Math.max(startY + computeRowHeight(table.headers), rowBottomY);

  // Line above header
  this.moveTo(startX, startY - rowSpacing)
    .lineTo(startX + usableWidth, startY - rowSpacing)
    .lineWidth(2)
    .stroke();

  // Separation line between headers and rows
  this.moveTo(startX, rowBottomY - rowSpacing * 0.5)
    .lineTo(startX + usableWidth, rowBottomY - rowSpacing * 0.5)
    .lineWidth(2)
    .stroke();

  table.rows.forEach((row, i) => {
    const rowHeight = computeRowHeight(row);

    // Switch to next page if we cannot go any further because the space is over.
    // For safety, consider 3 rows margin instead of just one
    if (startY + 3 * rowHeight < maxY) startY = rowBottomY + rowSpacing;
    else this.addPage();

    // Allow the user to override style for rows
    prepareRow(row, i);

    // Print all cells of the current row
    row.forEach((cell, i) => {
      this.text(cell, startX + i * columnContainerWidth, startY, {
        width: columnWidth,
        align: 'left'
      });
    });

    // Refresh the y coordinate of the bottom of this row
    rowBottomY = Math.max(startY + rowHeight, rowBottomY);

    // Separation line between rows
    this.moveTo(startX, rowBottomY - rowSpacing * 0.5)
      .lineTo(startX + usableWidth, rowBottomY - rowSpacing * 0.5)
      .lineWidth(1)
      .opacity(0.7)
      .stroke()
      .opacity(1); // Reset opacity after drawing the line
  });

  this.x = startX;
  this.moveDown();

  return this;
};

// * This genarates Image URL => Data URI
function getDataUri(url, cb) {
  var image = new Image();
  image.crossOrigin = 'anonymous';
  image.onload = function () {
    var canvas = document.createElement('canvas');
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    canvas.getContext('2d').drawImage(image, 0, 0);
    cb(canvas.toDataURL('image/png'));
  };
  image.src = url;
}

// * Return Font Awesome font as an arraybuffer returned from the xhr
function getFAData(cb) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    cb(xhr.response);
  };

  xhr.responseType = 'arraybuffer';
  xhr.open('GET', '/assets/fonts/fa-solid-900.ttf', true);
  xhr.send();
}

// * Return Decoded Base64 string
function convertBase64(str) {
  return atob(str);
}
