import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { LogindialogComponent } from '../logindialog/logindialog.component';
import { TermsdialogComponent } from '../termsdialog/termsdialog.component';
import { AnalyticsService } from '../services/analyticsService';
import { AuthService } from '../services/authService';
import { BookingService } from '../services/bookingService';
import { DataService } from '../services/detailsService';

export interface UserData {
  question: string;
  info: string;
}

@Component({
  selector: 'app-confirmdetails',
  templateUrl: './confirmdetails.component.html',
  styleUrls: ['./confirmdetails.component.css']
})
export class ConfirmdetailsComponent implements OnInit {
  @ViewChild('confirmForm') confirmFormDirective;

  confirmForm: FormGroup;
  detail: any;
  loggedIn: boolean;
  displayedColumns: string[];
  userData: UserData[];
  dataservice_data: any;
  errMess: string;

  constructor(
    private fb: FormBuilder,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private bookingService: BookingService,
    private dataService: DataService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.dataservice_data = this.dataService.getValue();

    this.analyticsService.eventEmitter(
      'confirm_details_page_visit',
      'engagement',
      '',
      'click'
    );

    if (!this.dataservice_data.user) {
      this.router.navigateByUrl('/booking');
    } else {
      window.scrollTo(0, 0);

      this.detail = this.dataService.getValue();

      this.displayedColumns = ['question', 'info'];
      this.userData = [
        { question: 'Total screening(s)', info: this.detail.user.length },
        {
          question: 'Date & time',
          info:
            new Date(this.detail.date).toDateString() +
            ', at ' +
            this.detail.timeslot
        },
        {
          question: 'Address',
          info:
            this.detail.address +
            ', ' +
            this.detail.street +
            ', ' +
            this.detail.landmark +
            ', ' +
            this.detail.pin
        },
        { question: 'Other Instructions', info: this.detail.otherinstructions }
      ];

      this.detail.user.forEach((val, i) => {
        this.userData.push({
          question: `${i + 1}. Name & email`,
          info: val.name + ', ' + val.emailid
        });
      });

      this.authService.loggedIn$.subscribe(logged => (this.loggedIn = logged));
    }
  }

  createForm() {
    this.confirmForm = this.fb.group({
      agree: [false, checkbox => (!checkbox.value ? { required: true } : false)]
    });
  }

  onSubmit() {
    if (!this.loggedIn) {
      this.dialog.open(LogindialogComponent, {
        width: '375px',
        height: '300px',
        panelClass: 'mat-confirm-dialog',
        disableClose: true
      });
    } else {
      this.bookingService.sendFormDetail(this.detail).subscribe(
        res => {
          this.dataService.changeBookingid(res.message);
          this.router.navigateByUrl('/payment');
        },
        errmess => (this.errMess = <any>errmess)
      );
    }
  }

  clickConsent() {
    this.dialog.open(TermsdialogComponent, {
      data: { consentForm: true },
      width: '375px',
      height: '78vh',
      panelClass: 'mat-confirm-dialog',
      disableClose: true
    });
  }

  clickTermsPrivacy() {
    this.dialog.open(TermsdialogComponent, {
      data: { privacyPolicy: true },
      width: '375px',
      height: '78vh',
      panelClass: 'mat-confirm-dialog',
      disableClose: true
    });
  }
}
