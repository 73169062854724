import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { skipWhile, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './services/authService';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const url: string = state.url;

    return this.authService.loggedInAdmin$.pipe(
      skipWhile(v => v === null),
      take(1),
      tap(authenticated => {
        if (!authenticated) {
          this.authService.checkLogInAdmin().subscribe(
            res =>
              res.success
                ? this.router.navigateByUrl(url)
                : this.router.navigateByUrl('/admin/login'),
            err => this.router.navigateByUrl('/admin/login')
          );
        }
      })
    );
  }
}
