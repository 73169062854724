import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../services/authService';


@Component({
  selector: 'app-adminsignup',
  templateUrl: './adminsignup.component.html',
})
export class AdminSignupComponent implements OnInit {
  signupForm: FormGroup;
    loading = false;
    submitted = false;
    error = '';
    private emailid : string;
    private password : string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,

    ) { 
        // redirect to home if already logged in
        // if (this.authService.userValue) { 
        //     this.router.navigate(['/']);
        // }
    }

    ngOnInit() {
        this.signupForm = this.formBuilder.group({
            emailid: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.signupForm.controls; }

    adminsignup() {
      if (this.emailid && this.password) {
        this.authService
          .adminSignup({emailid: this.emailid, password: this.password})
          .subscribe(
            res => {
              console.log(this.emailid)
            },
            err => {
              // console.log(err.error);
              err = err.error;
              if (err.success === false) {
                console.log(err);
              }
            }
          );
      } else {
        console.log(" type emailid and password")
      }
    }

 }