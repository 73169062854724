import { Routes } from "@angular/router";

import { HomeComponent } from "../home/home.component";
import { BookingsComponent } from "../bookings/bookings.component";
import { ConfirmdetailsComponent } from "../confirmdetails/confirmdetails.component";
import { NewbookingComponent } from "../newbooking/newbooking.component";
import { PaymentComponent } from "../payment/payment.component";
import { LogoutComponent } from "../logout/logout.component";
import { ReportsComponent } from "../reports/reports.component";
import { AdminLoginComponent } from "../adminlogin/adminlogin.component";
import { AdminSignupComponent } from "../adminsignup/adminsignup.component";
import { ForgotPasswordComponent } from "../forgotpassword/forgotpassword.component";
import { AdminComponent } from "../admin/admin.component";
import { CitiesComponent } from "../cities/cities.component";
import { OffersComponent } from "../offers/offers.component";
import { AuthGuard } from "../auth.guard";
import { RoleGuard } from "../role.guard";
import { GiftCardComponent } from "../pages/gift-card/gift-card.component";

export const routes: Routes = [
  { path: "newbooking", component: NewbookingComponent },
  { path: "bookings", component: BookingsComponent, canActivate: [AuthGuard] },
  { path: "confirm", component: ConfirmdetailsComponent },
  { path: "payment", component: PaymentComponent },
  { path: "logout", component: LogoutComponent, canActivate: [AuthGuard] },
  { path: "reports", component: ReportsComponent, canActivate: [AuthGuard] },
  { path: "", component: HomeComponent, pathMatch: "full" },
  { path: "admin/login", component: AdminLoginComponent },
  { path: "admin/signup", component: AdminSignupComponent },
  { path: "admin/forgotpassword", component: ForgotPasswordComponent },
  {
    path: "admin/dashboard",
    component: AdminComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "admin/cities",
    component: CitiesComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "admin/offers",
    component: OffersComponent,
    canActivate: [RoleGuard],
  },
  { path: "gift_card", component: GiftCardComponent },
];
