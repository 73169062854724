import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-termsdialog',
  templateUrl: './termsdialog.component.html',
  styleUrls: ['./termsdialog.component.css']
})
export class TermsdialogComponent implements OnInit {
  consentForm: boolean = false;
  privacyPolicy: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<TermsdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.consentForm = this.data.consentForm || false;
    this.privacyPolicy = this.data.privacyPolicy || false;
  }

  onSubmit() {
    this.dialogRef.close();
  }
}
