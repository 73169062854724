import { ChangeDetectorRef, Component, NgZone, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { GiftCardService } from "src/app/services/api/giftCardService";

declare var Razorpay: any;

@Component({
  selector: "app-gift-card",
  templateUrl: "./gift-card.component.html",
  styleUrls: ["./gift-card.component.css"],
})
export class GiftCardComponent implements OnInit {
  emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  emailFormControl = new FormControl("", [
    Validators.required,
    Validators.pattern(this.emailRegex),
  ]);

  couponFormControl = new FormControl({ value: "", disabled: true });

  razorpay: any;

  giftcardid: string = "";
  status: string;

  paymentSuccessful: boolean = false;

  razorPayOptions: any = {
    key: "",
    order_id: "",
    name: "Home Screening",
    theme: { color: "#F3618B" },
    prefill: {},
    image: "/assets/images/niramai-logo.png",
    amount: 1500,
    currency: "INR",
    description: "Niramai Health Analytix",
    handler: (response) => {
      this.ngZone.run(() => {
        this.giftCardService
          .updateGiftCardStatus({
            giftcardid: this.giftcardid,
            status: "RPayStatusConfirmed",
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          })
          .subscribe(
            (res) => {
              this.paymentSuccessful = true;
              console.log(res, this.paymentSuccessful);
              this.couponFormControl.setValue(this.giftcardid);
            },
            (err) => {}
          );
      });
    },
    modal: { escape: false, ondismiss: this.razorPayCloseHandler.bind(this) },
  };
  constructor(
    private ref: ChangeDetectorRef,
    private ngZone: NgZone,
    private giftCardService: GiftCardService
  ) {}

  razorPayCloseHandler() {
    console.log("Detected");
    this.ref.detectChanges();
  }

  ngOnInit() {}

  /* To copy Text from Textbox */
  copyInputMessage() {
    window.navigator["clipboard"].writeText(this.couponFormControl.value);
  }

  getCoupon = () => {
    if (this.emailFormControl.valid) {
      this.giftCardService
        .getGiftCardOrder({
          emailid: this.emailFormControl.value,
          cost: "1500",
        })
        .subscribe(
          (res) => {
            this.razorPayOptions.key = res.key;
            this.razorPayOptions.order_id = res.data.id;
            this.giftcardid = res.giftCardId;
            this.status = res.data.status;
          },
          (err) => {},
          () => {
            this.razorpay = new Razorpay(this.razorPayOptions);
            this.razorpay.open();
            // razorpay error handling
            // this.razorpay.on("payment.failed", function (response) {
            //   alert(response.error.code);
            //   alert(response.error.description);
            //   alert(response.error.source);
            //   alert(response.error.step);
            //   alert(response.error.reason);
            //   alert(response.error.metadata.order_id);
            //   alert(response.error.metadata.payment_id);
            // });
          }
        );
    }
  };
}
