import { Component, OnInit } from '@angular/core';
import { MatRadioChange } from '@angular/material';
import { AnalyticsService } from '../services/analyticsService';
import { BookingService } from '../services/bookingService';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  reports: Array<any> = [];
  all_reports: Array<any> = [];
  available_reports: Array<any> = [];
  awaiting_reports: Array<any> = [];
  filterType: string = 'all';
  isOpen = false;

  constructor(
    private analyticsService: AnalyticsService,
    private bookingService: BookingService
  ) {}

  ngOnInit() {
    this.analyticsService.eventEmitter(
      'reports_page_visit',
      'engagement',
      'user_logged_in',
      'click'
    );

    this.bookingService.getReportList().subscribe(
      res => {
        if (res.success === false) {
          this.reports = [];
        } else {
          res.forEach(val => {
            if (
              val.status == 'AwaitingReport' ||
              val.status == 'ReportAvailable' ||
              val.status == 'FeedbackCompleted'
            ) {
              val.date = new Date(val.date);
              this.all_reports.push(val);
              this.reports.push(val);
            }
          });
        }
      },
      err => console.log(err)
    );
  }

  onFilterChange(event: MatRadioChange) {
    this.filterType = event.value;

    if (this.filterType == 'all') {
      this.reports = this.all_reports;
    }

    if (this.filterType == 'available') {
      this.reports = [];
      this.all_reports.forEach(val => {
        if (
          val.status == 'ReportAvailable' ||
          val.status == 'FeedbackCompleted'
        ) {
          this.reports.push(val);
        }
      });
    }

    if (this.filterType == 'awaiting') {
      this.reports = [];
      this.all_reports.forEach(val => {
        if (val.status == 'AwaitingReport') {
          this.reports.push(val);
        }
      });
    }
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }
}
