import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AdminService } from '../services/adminService';

@Component({
  selector: 'app-adddiscountdialog',
  templateUrl: './adddiscountdialog.component.html',
  styleUrls: ['./adddiscountdialog.component.css']
})
export class AdddiscountdialogComponent implements OnInit {
  adddiscountForm: FormGroup;
  enabled= false;
  type = "single";
  submitted = false;
  error = '';
  role = '';
  errorMessage: string;
  err:boolean;
  private discountcode: string;
  private percentage: string;
  private codedescription: string;
  

  constructor(private dialogRef: MatDialogRef<AdddiscountdialogComponent>,
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    ) {
    
    }

  ngOnInit() {
    this.err= false;
    this.adddiscountForm = this.formBuilder.group({
      discountcode: ['', Validators.required],
      percentage: ['', Validators.required],
      codedescription: ['', Validators.required],
      type:['',Validators.required]
  });
  }
  get f() { return this.adddiscountForm.controls; }

  numericOnly(event): boolean { // restrict e,+,-,E characters in  input type number
    debugger
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    return true;

  }

adddiscount(){
  console.log(this.discountcode, this.percentage, this.codedescription, this.type)
    if (this.discountcode && this.percentage && this.codedescription) {
      this.adminService
        .addDiscount({discountcode: this.discountcode, percentage: this.percentage, codedescription: this.codedescription, enabled:this.enabled,type:this.type})
        .subscribe(
          res => {
            this.dialogRef.close();
            window.location.reload();
          },
          err => {
            console.log(err.error);
            err = err.error;
            this.err = true;
            if (err.success === false) {
              console.log(err);
            }
          }
        );
    } else {
      console.log("select both")
    }
}
reset(){
  this.adddiscountForm.reset(); 

  
}
  onNoClick(): void {
    this.dialogRef.close();
  }


}
