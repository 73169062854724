import { HttpClient, HttpHeaders } from "@angular/common/http";
import { baseURL } from "../../config/config";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
  withCredentials: true,
};

@Injectable({ providedIn: "root" })
export class GiftCardService {
  constructor(private http: HttpClient) {}

  getGiftCardOrder(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + "api/v1/homescreen/payment/giftcard",
      data,
      httpOptions
    );
  }

  updateGiftCardStatus(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + "api/v1/homescreen/payment/giftcardstatus",
      data,
      httpOptions
    );
  }
}
