import { Component, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AdminService } from '../services/adminService';
import { AdddiscountdialogComponent } from '../adddiscountdialog/adddiscountdialog.component';
import { EditdiscountdialogComponent } from '../editdiscountdialog/editdiscountdialog.component';
import { DeletediscountdialogComponent } from '../deletediscountdialog/deletediscountdialog.component';
import { AddcoupondialogComponent } from '../addcoupondialog/addcoupondialog.component';
import { EditcoupondialogComponent } from '../editcoupondialog/editcoupondialog.component';
import { DeletecoupondialogComponent } from '../deletecoupondialog/deletecoupondialog.component';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit {
  user:string;
  index: number;
  id: string;
  discountcheck: boolean;
  couponcheck: boolean;
  displayedColumns: string[] = ['discountcode', 'percentage','codedescription', 'action', 'status'];
  displayedColumns1: string[] = ['couponcode', 'couponpercentage','coupondescription','singleusage','multiplescreenings', 'action', 'status'];
  dataSource: MatTableDataSource<any>;
  couponSource: MatTableDataSource<any>;
  mulScrSource: MatTableDataSource<any>;
  multiplediscounts: Array<any> =["10","20","30","40","50","60","70","80","90","100"];
  defaultMultipleScreening: string;
  mulscrFlag:boolean= true;
  mulScrButton:string="Edit";
  bntStyle: string;
  mulId: string;
  

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('couponPaginator', { read: MatPaginator }) couponPaginator: MatPaginator;

  constructor(public dialog: MatDialog, private adminService: AdminService) {}
  ngOnInit() {
    this.bntStyle = 'edit';
    this.user = localStorage.getItem('user').split('@')[0];
    this.adminService.getmulipleScrDiscount().subscribe(
      res => {
        if (res.success === false) {
          console.log("empty")
        } else {
          this.mulScrSource = new MatTableDataSource(res);
          this.defaultMultipleScreening = this.mulScrSource.filteredData[0].percentage;
          this.mulId = this.mulScrSource.filteredData[0]._id
        }
      },
      err => console.log(err)
    );
    this.adminService.getDiscountsList().subscribe(
      res => {
        if (res.success === false) {
          console.log("empty")
        } else {
          this.dataSource = new MatTableDataSource(res);
          this.dataSource.paginator = this.paginator;
        }
      },
      err => console.log(err)
    );
    this.adminService.getCouponsList().subscribe(
      res => {
        if (res.success === false) {
          this.couponSource = new MatTableDataSource();
        } else {
          this.couponSource = new MatTableDataSource(res);
          this.couponSource.paginator = this.couponPaginator;
        }
      },
      err => console.log(err)
    );
  }

  onSelect(event){
    this.defaultMultipleScreening = event;
  }

  mulScrEdit(){
    console.log(this.mulscrFlag)
    if (this.mulscrFlag === true) {
      this.bntStyle = 'save';
      this.mulScrButton = 'Save'
      this.mulscrFlag = false;
    }
    else {
      //save % here
      this.mulScrButton = 'Edit'
      this.mulscrFlag = true;
      this.bntStyle = 'edit';
      if(this.defaultMultipleScreening&& this.mulId)
      console.log(typeof(this.defaultMultipleScreening),typeof(this.mulId))
      {
        this.adminService
        .multipleScrDiscount({percentage:this.defaultMultipleScreening, id:this.mulId})
        .subscribe(
          res => {
            window.location.reload();
          },
          err => {
            console.log(err.error);
            err = err.error;
            if (err.success === false) {
              console.log(err);
            }
          }
        );
      }

    }
  }



  addCoupon() {
    this.dialog.open(AddcoupondialogComponent,
    {width: '570px',
    height: '570px',
    panelClass: 'mat-confirm-dialog'
    });
  }

  editCoupon(i: number, id: string, Coupon_code: string, Coupon_discount: string, Coupon_message: string, single_screening_validity:string, multiple_screening_validity:string) {
    this.id = id;
    this.index = i;
    const dialogRef = this.dialog.open(EditcoupondialogComponent, {
      width: '570px',
    height: '570px',
    panelClass: 'mat-confirm-dialog',
    
      data: {id: id, Coupon_code:Coupon_code,Coupon_discount:Coupon_discount,Coupon_message:Coupon_message,single_screening_validity:single_screening_validity, multiple_screening_validity:multiple_screening_validity}
    });
  }

  deleteCoupon(i: number, id: string, Coupon_code: string, Coupon_discount: string, Coupon_message: string, single_screening_validity:string, multiple_screening_validity:string) {
    this.index = i;
    this.id = id;
    const dialogRef = this.dialog.open(DeletecoupondialogComponent, {
      width: '570px',
    height: '303px',
    panelClass: 'mat-confirm-dialog',
    data: {id: id, Coupon_code:Coupon_code,Coupon_discount:Coupon_discount,Coupon_message:Coupon_message,single_screening_validity:single_screening_validity, multiple_screening_validity:multiple_screening_validity}
  });
  }
  addDiscount() {
    this.dialog.open(AdddiscountdialogComponent,
    {width: '570px',
    height: '450px',
    panelClass: 'mat-confirm-dialog'
    });
  }

  startEdit(i: number, id: string, discountcode: string, percentage: string, codedescription: string) {
    this.id = id;
    this.index = i;
    const dialogRef = this.dialog.open(EditdiscountdialogComponent, {
      width: '570px',
    height: '450px',
    panelClass: 'mat-confirm-dialog',
      data: {id: id, discountcode:discountcode,percentage:percentage,codedescription:codedescription}
    });
  }

  deleteItem(i: number, id: string, discountcode: string, percentage: string, codedescription: string) {
    this.index = i;
    this.id = id;
    const dialogRef = this.dialog.open(DeletediscountdialogComponent, {
      width: '570px',
    height: '303px',
    panelClass: 'mat-confirm-dialog',
      data: {id: id, discountcode:discountcode,percentage:percentage,codedescription:codedescription}
    });
  }
  discountclick(event, discountcode, id){
    this.discountcheck = event.target.checked;
    this.adminService
    .discountEnabled({discountcode:discountcode, enabled:this.discountcheck, id:id})
    .subscribe(
      res => {
        window.location.reload();
      },
      err => {
        console.log(err.error);
        err = err.error;
        if (err.success === false) {
          console.log(err);
        }
      }
    );
  }
  couponclick(event, couponcode){
    this.couponcheck = event.target.checked;
    this.adminService
    .couponEnabled({Coupon_code:couponcode, enabled:this.couponcheck})
    .subscribe(
      res => {
        window.location.reload();
      },
      err => {
        console.log(err.error);
        err = err.error;
        if (err.success === false) {
          console.log(err);
        }
      }
    );
  }

}
