import {
  Component,
  OnInit,
  Input,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BookingService } from '../services/bookingService';

@Component({
  selector: 'app-feedbackdialog',
  templateUrl: './feedbackdialog.component.html',
  styleUrls: ['./feedbackdialog.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class FeedbackdialogComponent implements OnInit {
  @Input('rating') private rating: number = 0;
  @Input('bookingrating') private bookingrating: number = 0;
  @Input('screeningrating') private screeningrating: number = 0;
  @Input('customerrating') private customerrating: number = 0;
  @Input('experiencerating') private experiencerating: number = 0;
  @Input('solutionrating') private solutionrating: number = 0;
  @Input('starCount') private starCount: number = 5;
  private comment: string = '';
  private bookingId: string;
  submitted: boolean = false;

  private ratingArr = [];

  constructor(
    private dialogRef: MatDialogRef<FeedbackdialogComponent>,
    private bookingService: BookingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }

    this.bookingId = this.data.bookingId;
  }

  onCancel() {
    if (this.submitted == false) {
      this.dialogRef.close();
    } else {
      // * just reloads
      window.location.reload();
    }
  }

  onSubmit() {
    this.bookingService
      .sendFeedbackDetail({
        bookingrating: this.bookingrating,
        screeningrating: this.screeningrating,
        customerrating: this.customerrating,
        experiencerating: this.experiencerating,
        solutionrating: this.solutionrating,
        bookingId: this.bookingId,
        comment: this.comment
      })
      .subscribe(
        res => {
          this.submitted = true;
          this.onCancel();
        },
        err => console.log(err)
      );
  }

  onBookingRating(bookingrating: number) {
    this.bookingrating = bookingrating;
    return false;
  }
  onScreeningRating(screeningrating: number) {
    this.screeningrating = screeningrating;
    return false;
  }
  onCustomerSupportRating(customerrating: number) {
    this.customerrating = customerrating;
    return false;
  }
  onExperienceRating(experiencerating: number) {
    this.experiencerating = experiencerating;
    return false;
  }
  onSolutionRating(solutionrating: number) {
    this.solutionrating = solutionrating;
    return false;
  }

  showIconbooking(index: number) {
    if (this.bookingrating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }
  showIconscreening(index: number) {
    if (this.screeningrating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }
  showIconcustomer(index: number) {
    if (this.customerrating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }
  showIconexperience(index: number) {
    if (this.experiencerating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

  showIconsolution(index: number) {
    if (this.solutionrating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

  Isdisabled() {
    if (
      this.bookingrating &&
      this.screeningrating &&
      this.customerrating &&
      this.experiencerating &&
      this.solutionrating
    ) {
      return false;
    }
    return true;
  }
}
