import { Component, OnInit,Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../services/authService';
import {
  BreakpointObserver,
  Breakpoints
} from '@angular/cdk/layout';



@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {
routerurl="string"
  sidenav = {
    opened: false,
    mode: 'side',
    fixedTopGap: 64,
  };
  mode = 'side';
  handset = false;
  tablet = false;
  web = false;

  sidebarType = 'main';
  user='';


    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        breakpointObserver: BreakpointObserver
        
    ) { 

    }

    ngOnInit() {
     this.user = localStorage.getItem('user');
     this.routerurl= this.router.url;
    }
 
    adminlogout() {
      this.authService
      .logOut()
      .subscribe(
        res => (  
        this.router.navigate(['/admin/login']),
        localStorage.removeItem('currentUser'),
        localStorage.removeItem('user')),
        
        err => {
          err = err.error;
        }
      );
  } 
    ngOnDestroy(): void {
    }
  
    ngAfterViewInit() {

    }

  

 }


