import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '../services/analyticsService';
import { AuthService } from '../services/authService';
import { DataService } from '../services/detailsService';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private dataService: DataService,
    private router: Router
  ) {}

  ngOnInit() {
    this.analyticsService.eventEmitter(
      'logout_page_visit',
      'engagement',
      'user_logged_out',
      'click'
    );

    this.authService.logOut().subscribe(
      res => {
        this.dataService.resetValue();
        this.dataService.resetMobileno();
        this.router.navigateByUrl('/');
      },
      err => console.log(err)
    );
  }
}
