import { HttpClient, HttpHeaders } from '@angular/common/http';
import { baseURL } from '../config/config';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { timeout } from 'rxjs/operators';
import { throwError, TimeoutError } from 'rxjs';



import { ProcessHTTPMsgService } from './process-httpmsg.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({ providedIn: 'root' })
export class BookingService {
  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHTTPMsgService
  ) {}

  sendFormDetail(data: any): Observable<any> {
    return this.http
      .post<any>(baseURL + 'api/v1/homescreen/booking', data, httpOptions)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  sendPaymentLink(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/payment/link',
      data,
      httpOptions
    );
  }

  sendOrderDetail(data: any): Observable<any> {
    return this.http
      .post<any>(baseURL + 'api/v1/homescreen/payment/order', data, httpOptions)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  sendPaymentStatus(data: any): Observable<any> {
    return this.http
      .post<any>(
        baseURL + 'api/v1/homescreen/payment/rcheckout',
        data,
        httpOptions
      )
      .pipe(timeout(40000),
        catchError((error) => {
          return throwError('Timeout Exception, Please Try Again!');
        })
    );
  }

  sendFeedbackDetail(data: any): Observable<any> {
    return this.http
      .post<any>(baseURL + 'api/v1/homescreen/user/feedback', data, httpOptions)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getBookingsList(): Observable<any> {
    return this.http
      .get<any>(baseURL + 'api/v1/homescreen/bookings', httpOptions)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getCoupons(coupon_code: string): Observable<any> {
    return this.http
      .get<any>(
        baseURL + 'api/v1/homescreen/coupon/' + coupon_code,
        httpOptions
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  addCoupon(data: any): Observable<any> {
    return this.http
      .post<any>(baseURL + 'api/v1/homescreen/coupon', data, httpOptions)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  checkCoupon(data: any): Observable<any> {
    return this.http
      .post<any>(baseURL + 'api/v1/homescreen/couponcheck', data, httpOptions)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  cancelBooking(data: any): Observable<any> {
    return this.http
      .post<any>(
        baseURL + 'api/v1/homescreen/booking/cancel',
        data,
        httpOptions
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getReportList(): Observable<any> {
    return this.http
      .get<any>(baseURL + 'api/v1/homescreen/report', httpOptions)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getInvoiceData(bookingId: string): Observable<any> {
    return this.http.get<any>(
      baseURL + 'api/v1/homescreen/payment/invoice/' + bookingId,
      httpOptions
    );
  }

  sendInvoicePDF(data: any): Observable<any> {
    const formData = new FormData();
    formData.append('invoice', data.file, 'invoice.pdf');
    formData.append('bookingid', data.bookingid);
    formData.append('invoiceno', data.invoiceno);
    formData.append('name', data.name);
    formData.append('date', data.date);
    formData.append('emailid', data.emailid);
    formData.append('orderid', data.orderid);
    formData.append('amount', data.amount);
    formData.append('status', data.status);

    const httpOptions = {
      // headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
      withCredentials: true
    };
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/payment/invoice',
      formData,
      httpOptions
    );
  }
}
