import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AdminService } from '../services/adminService';

@Component({
  selector: 'app-deletecitydialog',
  templateUrl: './deletecitydialog.component.html',
  styleUrls: ['./deletecitydialog.component.css']
})
export class DeletecitydialogComponent implements OnInit {
  deletecityForm: FormGroup;
  private checklist: any[];
  hour:string;
  constructor(private formBuilder: FormBuilder,public dialogRef: MatDialogRef<DeletecitydialogComponent>,
    private adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 

    }
  ngOnInit() {

    this.deletecityForm = this.formBuilder.group({
      city: ['', Validators.required],
      hour: ['', Validators.required]
  });
  
  }



    reset(){
      this.checklist.forEach(val => {
        val.isSelected = false;
      });
    }

onNoClick(): void {
this.dialogRef.close();
}

delete(): void {
this.adminService
.deleteCity({city: this.data.city, hour: this.data.hour,id: this.data.id,})
.subscribe(
  res => {
    window.location.reload();
  },
  err => {
    console.log(err.error);
    err = err.error;
    if (err.success === false) {
      console.log(err);
    }
  }
);

}

}
