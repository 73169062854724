import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AdminService } from '../services/adminService';



@Component({
  selector: 'app-editcitydialog',
  templateUrl: './editcitydialog.component.html',
  styleUrls: ['./editcitydialog.component.css']
})
export class EditcitydialogComponent implements OnInit{
  editcityForm: FormGroup;
  private checklist: any[];
  hour:string;
  checked:boolean;
  constructor(private formBuilder: FormBuilder,public dialogRef: MatDialogRef<EditcitydialogComponent>,
    private adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.checklist = [
        { id: 1, value: "24 hours", isSelected: false },
        { id: 2, value: "72 hours", isSelected: false }
      ];
    }
  ngOnInit() {
    if(this.data.hour=='24 hours'){
      this.checklist = [
        { id: 1, value: "24 hours", isSelected: true },
        { id: 2, value: "72 hours", isSelected: false }
      ];
    }
    else{
      this.checklist = [
        { id: 1, value: "24 hours", isSelected: false },
        { id: 2, value: "72 hours", isSelected: true }
      ];

    }
 
    this.editcityForm = this.formBuilder.group({
      city: ['', Validators.required],
      hour: ['', Validators.required]
  });
  
  }


    isAllSelected(item) {
      this.checklist.forEach(val => {
       
        if (val.id == item.id){
          val.isSelected = !val.isSelected;
          this.hour= val.value;
          this.checked = val.isSelected
        } 
        else {
          val.isSelected = false;
        }
      });
    }

    reset(){
      this.checked = false;
      this.checklist.forEach(val => {
        val.isSelected = false;
      });
    }

onNoClick(): void {
this.dialogRef.close();
}

save(): void {
if (this.data.city && this.hour && this.checked) {
  this.adminService
    .editCity({city: this.data.city, hour: this.hour,id:this.data.id,})
    .subscribe(
      res => {
        this.dialogRef.close();
        window.location.reload();
      },
      err => {
        console.log(err.error);
        err = err.error;
        if (err.success === false) {
          console.log(err);
        }
      }
    );
} else {
  console.log("select both")
}
}
}




