import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AdminService } from '../services/adminService';

@Component({
  selector: 'app-deletecoupondialog',
  templateUrl: './deletecoupondialog.component.html',
  styleUrls: ['./deletecoupondialog.component.css']
})
export class DeletecoupondialogComponent implements OnInit {
  deletecouponForm: FormGroup;
  enabled= true;
  submitted = false;
  error = '';
  role = '';
  errorMessage: string;
  err:boolean;
  private couponcode: string;
  private couponpercentage: string;
  private couponcodedescription: string;
  private singlescreening:boolean;
  private multiplescreening:boolean;
  constructor(private formBuilder: FormBuilder,public dialogRef: MatDialogRef<DeletecoupondialogComponent>,
    private adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 

    }
  ngOnInit() {
this.deletecouponForm = this.formBuilder.group({
  couponcode: ['', Validators.required],
  couponpercentage: ['', Validators.required],
  couponcodedescription: ['', Validators.required]
});

  
  }





onNoClick(): void {
this.dialogRef.close();
}

delete(): void {
this.adminService
.deleteCoupon({Coupon_code: this.data.Coupon_code, Coupon_discount: this.data.Coupon_discount, Coupon_message:this.data.Coupon_message, single_screening_validity: this.data.single_screening_validity, multiple_screening_validity: this.data.multiple_screening_validity ,id:this.data.id})
.subscribe(
  res => {
    window.location.reload();
  },
  err => {
    console.log(err.error);
    err = err.error;
    if (err.success === false) {
      console.log(err);
    }
  }
);

}

}
