import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirmdialog',
  templateUrl: './confirmdialog.component.html',
  styleUrls: ['./confirmdialog.component.css'],
})
export class ConfirmdialogComponent implements OnInit {
  isPayLater: boolean;
  scrPrice: number;

  constructor(
    private dialogRef: MatDialogRef<ConfirmdialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.isPayLater = this.data.isPayLater;
    this.scrPrice = this.data.scrPrice;
  }

  onSubmit() {
    this.dialogRef.close();
    this.router.navigateByUrl('/');
  }
}
