import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AdminService } from '../services/adminService';


@Component({
  selector: 'app-addcitydialog',
  templateUrl: './addcitydialog.component.html',
  styleUrls: ['./addcitydialog.component.css']
})

export class AddcitydialogComponent implements OnInit {
  addcityForm: FormGroup;
  enabled= true;
  submitted = false;
  error = '';
  role = '';
  checked:boolean;
  errorMessage: string;
  err:boolean;
  private city : string;
  private checklist: any[];
  private hour: string;
  

  constructor(private dialogRef: MatDialogRef<AddcitydialogComponent>,
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    ) {
      this.checklist = [
        { id: 1, value: "24 hours", isSelected: false },
        { id: 2, value: "72 hours", isSelected: false }
      ];
    }

  ngOnInit() {
    this.err= false;
    this.addcityForm = this.formBuilder.group({
      city: ['', Validators.required],
      hour: ['', Validators.required]
  });
  }
  get f() { return this.addcityForm.controls; }

  isAllSelected(item) {
    this.checklist.forEach(val => {
      if (val.id == item.id){
        val.isSelected = !val.isSelected;
        this.hour= val.value;
        this.checked = val.isSelected;
      } 
      else {
        val.isSelected = false;
      }
    });
  }

addcity(){
    if (this.city && this.hour && this.checked) {
      this.adminService
        .addCity({city: this.city, hour: this.hour, enabled: this.enabled})
        .subscribe(
          res => {
            this.dialogRef.close();
            window.location.reload();
          },
          err => {
            console.log(err.error);
            err = err.error;
            this.err = true;
            if (err.success === false) {
              console.log(err);
            }
          }
        );
    } else {
      console.log("select both")
    }
}
reset(){
  this.addcityForm.reset();
  this.checked = false;
  this.checklist.forEach(val => {
    val.isSelected = false;
  });
}
  onNoClick(): void {
    this.dialogRef.close();
  }

}
