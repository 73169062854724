import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style } from '@angular/animations';
// import { Cities } from '../newbooking/detailsform';
import { AuthService } from '../services/authService';
import { DataService } from '../services/detailsService';
import { AnalyticsService } from '../services/analyticsService';
import { AdminService } from '../services/adminService';
import { FeedbackdialogComponent } from '../feedbackdialog/feedbackdialog.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('toggleBox', [
      state('open', style({ display: 'flex' })),
      state('closed', style({ display: 'none', transform: 'scale(.5)' }))
    ])
  ]
})
export class HomeComponent implements OnInit {
  cityForm: FormGroup;
  city: any;
  defaultCity:any;
  isOpen;
  bookingId: string;
  bookingStatus: string;
  role: string;
  cities2: Array<any> = [];
  cityname: Array<any> =[];


  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private dataservice: DataService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private adminService: AdminService,
  ) {
    this.createForm();
    // * Setting City from routes
    // ! NOTE: query param are case sensitive
    this.route.queryParams.subscribe(query => {
      if (query['city'] && this.cityname.includes(query['city'])) {
        this.dataservice.changeLocation(query['city']);
        this.router.navigateByUrl('/newbooking');
      }
    });
  }

  createForm() {
    this.cityForm = this.fb.group({ city: '' });
  }

  ngOnInit() {
    this.adminService.getCitiesList().subscribe(
      res => {
        if (res.success === false) {
          this.cities2 = [];
        } else {
          this.cities2 = res;
          for(let i=0; i<this.cities2.length;i++){
            if(this.cities2[i].enabled==true){
              this.cityname.push(this.cities2[i].city);
              this.cityForm.get('city').setValue(this.cityname[0]);
              this.defaultCity = this.cityname[0]
            }
          }
          console.log(this.cityname[0])
        }
      },
      err => console.log(err)
    );

    this.analyticsService.eventEmitter(
      'home_page_visit',
      'engagement',
      '',
      'click'
    );

    this.authService.checkLogIn().subscribe(
      res => {
        this.bookingStatus = res.status;
        this.bookingId = res.bookingId;
        this.dataservice.changeMobileno(res.mobileno);
      },
      err => console.log(err)
    );
  }

  onSubmit() {
    this.city = this.cityForm.value.city;
    if (!this.city) {
      this.snackBar.open('Location is Mandatory', '', {
        panelClass: ['simple-snack-bar'],
        duration: 2000
      });
    } else {
      this.dataservice.changeLocation(this.city);
      this.router.navigateByUrl('/newbooking');
    }
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  ratePopup() {
    this.dialog.open(FeedbackdialogComponent, {
      data: { bookingId: this.bookingId },
      width: '800px',
      height: '550px',
      panelClass: 'mat-confirm-dialog',
      disableClose: true
    });
  }
}
