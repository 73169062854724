import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { LogindialogComponent } from '../logindialog/logindialog.component';
import { AuthService } from '../services/authService';
import { DataService } from '../services/detailsService';

@Component({
  selector: 'app-signupdialog',
  templateUrl: './signupdialog.component.html',
  styleUrls: ['./signupdialog.component.css']
})
export class SignupdialogComponent implements OnInit {
  private mobile: number;
  otpSent: boolean = false;
  private otp: number;
  private mobinvalid: boolean = false;
  private mobreg;
  private otpinvalid: boolean = false;
  private otpwrong: boolean = false;
  private otpwrongmessage: string;

  constructor(
    private dialogRef: MatDialogRef<SignupdialogComponent>,
    private dialog: MatDialog,
    private authService: AuthService,
    private dataservice: DataService
  ) {}

  ngOnInit() {}

  onCancel() {
    this.dialogRef.close();
  }

  sendOTP() {
    if (
      this.mobile &&
      this.mobile.toString().length === 10 &&
      this.mobile.toString().match('^[0-9]*$')
    ) {
      this.authService
        .sendSignupDetail({ mobileno: Number(this.mobile) })
        .subscribe(
          res => (this.otpSent = true),
          err => {
            err = err.error;
            // console.log(err);
            if (err.success == 'false' && err.registered == true) {
              this.mobreg = true;
              this.mobinvalid = false;
            }
          }
        );
    } else {
      this.mobinvalid = true;
      this.mobreg = false;
    }
  }

  signup() {
    if (
      this.otp &&
      this.otp.toString().length === 6 &&
      this.otp.toString().match('^[0-9]*$')
    ) {
      this.authService
        .sendOTPDetail({ mobileno: Number(this.mobile), otptext: this.otp })
        .subscribe(
          res => {
            this.dataservice.changeMobileno(Number(this.mobile));
            this.dialogRef.close();
          },
          err => {
            // console.log(err.error);
            err = err.error;
            if (err.success === false) {
              this.otpwrong = true;
              this.otpwrongmessage = err.message;
              this.otpinvalid = false;
            }
          }
        );
    } else {
      this.otpinvalid = true;
      this.otpwrong = false;
    }
  }

  editMobile() {
    this.otp = null;
    this.mobinvalid = false;
    this.mobreg = false;
    this.otpinvalid = false;
    this.otpwrong = false;
    this.otpSent = false;
  }

  login() {
    this.dialogRef.close();
    this.dialog.open(LogindialogComponent, {
      width: '375px',
      height: '300px',
      panelClass: 'mat-confirm-dialog',
      disableClose: true
    });
  }

  resendOTP() {
    if (
      this.mobile &&
      this.mobile.toString().length === 10 &&
      this.mobile.toString().match('^[0-9]*$')
    ) {
      this.authService
        .reSendLoginDetail({ mobileno: Number(this.mobile) })
        .subscribe(
          res => (this.otpSent = true),
          err => console.log(err)
        );
    } else {
      this.mobinvalid = true;
    }
  }
}
