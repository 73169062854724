import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../services/analyticsService';
import { BookingService } from '../services/bookingService';
import { InvoiceService } from '../services/invoiceService';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.css']
})
export class BookingsComponent implements OnInit {
  bookingType: string;
  bookings: Array<any> = [];
  bookings_ongoing: Array<any> = [];
  bookings_history: Array<any> = [];

  constructor(
    private analyticsService: AnalyticsService,
    private bookingService: BookingService,
    private invoiceService: InvoiceService
  ) {}

  ngOnInit() {
    this.analyticsService.eventEmitter(
      'bookings_page_visit',
      'engagement',
      'user_logged_in',
      'click'
    );

    this.bookingType = 'ongoing';

    this.bookingService.getBookingsList().subscribe(
      res => {
        if (res.success === false) {
          this.bookings = [];
        } else {
          this.bookings = res;
          this.bookings.forEach(val => {
            val.date = new Date(val.date);
            // * Old
            if (new Date(val.date).getTime() < new Date().getTime()) {
              // * report available/awaiting
              if (
                val.status == 'AwaitingReport' ||
                val.status == 'ReportAvailable' ||
                val.status == 'FeedbackCompleted'
              ) {
                val.title = `Screening for booking id ${
                  val.bookingid
                } was completed on ${new Date(val.date).toDateString()}`;
                this.bookings_history.push(val);
              }

              // * cancelled
              if (val.status == 'BookingCancelled') {
                val.title = `Screening scheduled on ${new Date(
                  val.date
                ).toDateString()} for booking id ${val.bookingid} is cancelled`;
                this.bookings_history.push(val);
              }

              // * confirmed
              if (val.status == 'BookingConfirmed' || 'FreeScreening') {
                val.title = `Screening appointment for booking id ${val.bookingid} is not initiated`;
                this.bookings_history.push(val);
              }
              // * Future
            } else {
              // * confirmed
              if (val.status == 'BookingConfirmed' || 'FreeScreening') {
                val.title = `Screening appointment for booking id ${val.bookingid} is confirmed`;
                this.bookings_ongoing.push(val);
              }

              // * cancelled
              if (val.status == 'BookingCancelled') {
                val.title = `Screening appointment for booking id ${val.bookingid} is cancelled`;
                this.bookings_ongoing.push(val);
              }

              // * report available/awaiting
              if (
                val.status == 'AwaitingReport' ||
                val.status == 'ReportAvailable' ||
                val.status == 'FeedbackCompleted'
              ) {
                val.title = `Screening for booking id ${
                  val.bookingid
                } was completed on ${new Date(val.date).toDateString()}`;
                this.bookings_history.push(val);
              }
            }
          });
        }
      },
      err => console.log(err)
    );
  }

  onValChange(val: string) {
    this.bookingType = val;
  }

  cancelBooking(bookingid: string) {
    this.bookingService.cancelBooking({ bookingid }).subscribe(
      res => {
        // console.log(res);
        this.bookings_ongoing = this.bookings_ongoing.filter(
          val => val.bookingid != bookingid
        );
      },
      err => console.log(err)
    );
  }

  downloadInvoice(bookingid: string) {
    this.bookingService.getInvoiceData(bookingid).subscribe(
      res => {
        const options = {
          name: res.name,
          address: res.address,
          street: res.street,
          landmark: res.landmark,
          city: res.city,
          pincode: res.pincode,
          invoice: res.invoice,
          date: res.date,
          pan: res.pan,
          gst: res.gst,
          signatory_name: res.signatory_name,
          signatory_title: res.signatory_title,
          // bank_account: res.bank_account,
          // bank_info: res.bank_info,
          // ifsc: res.ifsc,
          total: res.total,
          total_amount: res.total,
          short_url: res.short_url
        };

        this.invoiceService.generatePDF(options).subscribe(stream => {
          const blob = stream.toBlob('application/pdf');
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  downloadInvoicePayLater(bookingid: string) {
    this.bookingService.getInvoiceData(bookingid).subscribe(
      res => {
        const options = {
          name: res.name,
          address: res.address,
          street: res.street,
          landmark: res.landmark,
          city: res.city,
          pincode: res.pincode,
          invoice: res.invoice,
          date: res.date,
          pan: res.pan,
          gst: res.gst,
          signatory_name: res.signatory_name,
          signatory_title: res.signatory_title,
          // bank_account: res.bank_account,
          // bank_info: res.bank_info,
          // ifsc: res.ifsc,
          total: res.total,
          total_amount: res.total,
          short_url: res.short_url
        };

        this.invoiceService.generatePDFPayLater(options).subscribe(stream => {
          const blob = stream.toBlob('application/pdf');
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        });
      },
      err => {
        console.log(err);
      }
    );
  }
}
