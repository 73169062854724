import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AdminService } from '../services/adminService';

@Component({
  selector: 'app-addcoupondialog',
  templateUrl: './addcoupondialog.component.html',
  styleUrls: ['./addcoupondialog.component.css']
})
export class AddcoupondialogComponent implements OnInit {
  addcouponForm: FormGroup;
  enabled= true;
  submitted = false;
  error = '';
  role = '';
  errorMessage: string;
  err:boolean;
  private couponcode: string;
  private couponpercentage: string;
  private couponcodedescription: string;
  private singlescreening:boolean;
  private multiplescreening:boolean;

  constructor(private dialogRef: MatDialogRef<AddcoupondialogComponent>,
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    ) {
    
    }

  ngOnInit() {
    this.err= false;
    this.singlescreening = false;
    this.multiplescreening = false;
    this.addcouponForm = this.formBuilder.group({
      couponcode: ['', Validators.required],
      couponpercentage: ['', Validators.required],
      couponcodedescription: ['', Validators.required],
  });
  }
  get f() { return this.addcouponForm.controls; }

  numericOnly(event): boolean { // restrict e,+,-,E characters in  input type number
    debugger
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    return true;

  }

  changed(evt) {
    this.singlescreening = evt.target.checked;
  }
  change(evt) {
    this.multiplescreening = evt.target.checked;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

addcoupon(){
    if (this.couponcode && this.couponpercentage && this.couponcodedescription) {
      this.adminService
        .addCoupon({Coupon_code: this.couponcode, Coupon_discount: this.couponpercentage, Coupon_message: this.couponcodedescription,single_screening_validity:this.singlescreening, multiple_screening_validity:this.multiplescreening, enabled:this.enabled})
        .subscribe(
          res => {
            this.dialogRef.close();
            window.location.reload();
          },
          err => {
            console.log(err.error);
            err = err.error;
            this.err = true;
            if (err.success === false) {
              console.log(err);
            }
          }
        );
    } else {
      console.log("select both")
    }
}
reset(){
  this.addcouponForm.reset(); 

  
}
  onNoClick(): void {
    this.dialogRef.close();
  }


}
