import { HttpClient, HttpHeaders } from '@angular/common/http';
import { baseURL } from '../config/config';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessHTTPMsgService } from './process-httpmsg.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({ providedIn: 'root' })
export class AdminService {
  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHTTPMsgService
  ) {}


  addCity(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/admin/addcity',
      data,
      httpOptions
    );
  }

  addDiscount(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/admin/adddiscount',
      data,
      httpOptions
    );
  }

  addCoupon(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/admin/addcoupon',
      data,
      httpOptions
    );
  }

  editCity(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/admin/editcity',
      data,
      httpOptions
    );
  }

  editDiscount(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/admin/editdiscount',
      data,
      httpOptions
    );
  }
  editCoupon(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/admin/editcoupon',
      data,
      httpOptions
    );
  }

  deleteCity(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/admin/deletecity',
      data,
      httpOptions
    );
  }

  deleteDiscount(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/admin/deletediscount',
      data,
      httpOptions
    );
  }
  deleteCoupon(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/admin/deletecoupon',
      data,
      httpOptions
    );
  }
  couponEnabled(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/admin/couponenable',
      data,
      httpOptions
    );
  }
  discountEnabled(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/admin/discountenable',
      data,
      httpOptions
    );
  }
  cityEnabled(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/admin/cityenable',
      data,
      httpOptions
    );
  }
  
  
  getCouponsList(): Observable<any> {
    return this.http
      .get<any>(baseURL + 'api/v1/homescreen/coupons', httpOptions)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getDiscountsList(): Observable<any> {
    return this.http
      .get<any>(baseURL + 'api/v1/homescreen/discounts', httpOptions)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getCitiesList(): Observable<any> {
    return this.http
      .get<any>(baseURL + 'api/v1/homescreen/cities', httpOptions)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getmulipleScrDiscount(): Observable<any> {
    return this.http
      .get<any>(baseURL + 'api/v1/homescreen/admin/getmulScrDis', httpOptions)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  multipleScrDiscount(data: any): Observable<any> {
    return this.http.post<any>(
      baseURL + 'api/v1/homescreen/admin/mulScrDis',
      data,
      httpOptions
    );
  }


}
