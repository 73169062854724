import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LogindialogComponent } from './logindialog/logindialog.component';
import { SignupdialogComponent } from './signupdialog/signupdialog.component';
import { AuthService } from './services/authService';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  loggedIn: boolean;
  showComponent: boolean;
  show:boolean;

  constructor(private dialog: MatDialog, private authService: AuthService, private router: Router) {
    router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        if(event.url.includes('admin')){
          this.showComponent=false;
        }else{
          this.showComponent=true;
        }
      }
    });
  }

  ngOnInit() {
    this.authService.loggedIn$.subscribe(logged => (this.loggedIn = logged));
  }

  onLogin() {
    this.dialog.open(LogindialogComponent, {
      width: '375px',
      height: '300px',
      panelClass: 'mat-confirm-dialog',
      disableClose: true
    });
  }

  onSignup() {
    this.dialog.open(SignupdialogComponent, {
      width: '375px',
      height: '300px',
      panelClass: 'mat-confirm-dialog',
      disableClose: true
    });
  }
}
