import { Component, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Detail, TimeSlot } from "./detailsform";
import { BookingService } from "../services/bookingService";
import { AnalyticsService } from "../services/analyticsService";
import { DataService } from "../services/detailsService";
import { GeoService } from "../services/geoService";
import { AdminService } from "../services/adminService";
import { DuplicatedialogComponent } from "../duplicatedialog/duplicatedialog.component";
import { MatDialog } from "@angular/material";

@Component({
  selector: "app-newbooking",
  templateUrl: "./newbooking.component.html",
  styleUrls: ["./newbooking.component.css"],
})
export class NewbookingComponent implements OnInit {
  @ViewChild("detailForm") detailFormDirective;
  detailForm: FormGroup;
  detail: Detail;
  timeslot = TimeSlot;
  dataservice_data: any;
  today: Date = new Date();
  tomorrow: Date = new Date();
  latitude: number;
  longitude: number;
  flag: boolean = false;
  isDuplicate: boolean = false;
  duplicateName: string;
  valueArr: Array<any> =[];
  Arr: Array<any> =[];
  index:string;
  cities: Array<any> = [];
  Cities72HourRule: Array<any> = [];
  email_regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  readOnlyMobilenoInput: boolean = false;

  formErrors = {
    name: "",
    emailid: "",
    mobileno: "",
    user: [{ emailid: "", mobileno: "" }],
    pin: "",
  };

  validationMessages = {
    name: {
      pattern: "Same name is invalid",
    },
    emailid: {
      required: "Email is required",
      pattern: "Email not in valid format",
    },
    mobileno: {
      required: "Mobile is required",
      pattern: "Mobile must contain only numbers",
      minlength: "Mobile must be at least 10 char long",
      maxlength: "Mobile cannot be more than 10 char long",
    },
    pin: {
      required: "PIN is required",
      pattern: "PIN must contain only numbers",
      minlength: "PIN must be at least 6 char long",
      maxlength: "PIN cannot be more than 6 char long",
    },
  };

  constructor(
    private fb: FormBuilder,
    private analyticsService: AnalyticsService,
    private bookingService: BookingService,
    private dataservice: DataService,
    private geoService: GeoService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private adminService: AdminService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.dataservice_data = this.dataservice.getValue();

    if (!this.dataservice_data.city) {
      this.router.navigateByUrl("/");
    }

    this.analyticsService.eventEmitter(
      "new_bookings_page_visit",
      "engagement",
      "",
      "click"
    );

    this.adminService.getCitiesList().subscribe(
      (res) => {
        if (res.success === false) {
          this.cities = [];
        } else {
          this.cities = res;
          for (let i = 0; i < this.cities.length; i++) {
            if (this.cities[i].hour == "72 hours") {
              this.Cities72HourRule.push(this.cities[i].city);
            }
          }

          // * Tomorrow will start from this
          if (this.dataservice_data.timeslot) {
            this.onTimeSlotChange(this.dataservice_data.timeslot);
          } else {
            if (this.Cities72HourRule.includes(this.dataservice_data.city)) {
              if (this.today.getHours() >= 18) {
                this.tomorrow.setDate(this.today.getDate() + 4);
              } else {
                this.tomorrow.setDate(this.today.getDate() + 3);
              }
            } else {
              if (this.today.getHours() >= 18) {
                this.tomorrow.setDate(this.today.getDate() + 2);
              } else {
                this.tomorrow.setDate(this.today.getDate() + 1);
              }
            }
          }
        }
      },
      (err) => console.log(err)
    );

    if (this.dataservice.getMobileno()) {
      this.readOnlyMobilenoInput = true;
    }

    window.scrollTo(0, 0);
  }

  duplicateDialog() {
    const dialogRef = this.dialog.open(DuplicatedialogComponent, {
      width: "580px",
      height: "250px",
      panelClass: "mat-confirm-dialog",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res.data == "No") {
        var form = this.detailForm;
        // this.detailForm.value.user[this.index].name = "";
        this.detailForm.controls.user["controls"][this.index].controls.name.setValue(
          ""
        );
      }
      if (res.data == "Yes") {
        console.log("yes")
        this.flag = false;
        console.log("this.valueArr",this.valueArr)
        this.Arr = this.valueArr.shift();
        console.log(this.valueArr,"valueArr")
      }
    });
  }
  createForm() {
    this.dataservice_data = this.dataservice.getValue();

    this.detailForm = this.fb.group({
      date: [this.dataservice_data.date || "", Validators.required],
      timeslot: [this.dataservice_data.timeslot || "", Validators.required],
      user: this.fb.array(
        this.dataservice_data.user ? this.fillUserRow() : [this.initUserRow()]
      ),
      address: [this.dataservice_data.address || "", Validators.required],
      street: [this.dataservice_data.street || "", Validators.required],
      landmark: [this.dataservice_data.landmark || "", Validators.required],
      pin: [
        this.dataservice_data.pin || "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(6),
          Validators.maxLength(6),
        ],
      ],
      otherinstructions: this.dataservice_data.otherinstructions || "",
      agree: [
        false,
        (checkbox) => {
          return !checkbox.value ? { required: true } : false;
        },
      ],
    });

    this.detailForm.valueChanges.subscribe((data) => this.onValueChanged(data));
    this.onValueChanged();
  }

  onValueChanged(data?: any) {
    if (!this.detailForm) {
      return;
    }
    const form = this.detailForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] = messages[key];
              break; // * Capture only first error
            }
          }
        }
      }
    }
  }
  duplicateElement(value,index){
    var form = this.detailForm;
    this.valueArr = form.value.user.map(function (item) {
      return item.name;
    });
    for(let i=0;i<this.valueArr.length;i++){
      console.log(i,index,value,this.valueArr[i],"duplicateElement")
      if(i===index|| index==this.valueArr.length-1 || value==this.valueArr[i]){
        return true;

      }
      else return false;

    }  }
  onCheckNameChange(event){
    var form = this.detailForm;
    this.valueArr = form.value.user.map(function (item) {
      return item.name;
    });
    var indexArr = form.value.user.map(function (item) {
      return item.name;
    });
    if (this.flag != false) {
      var findDuplicatesindex = attD => attD.map((item, index) => {
        if (attD.indexOf(item) !== index)
          return index;
      }).filter(x => x);  
      var index = findDuplicatesindex(indexArr);
      this.index = index.slice(-1).pop()?index.slice(-1).pop():""
      this.isDuplicate = this.duplicateElement(event.target.value,this.index)
      if (this.isDuplicate === true) {
        this.duplicateDialog();
      }
    }

  }
  onArrFormValueChange(i?: number) {
    var form = this.detailForm;
    if (form.controls.user) {
      const index = i || 0;
      const user_form = form.controls.user["controls"][index];
      for (const field in this.formErrors) {
        const control = user_form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors["user"][i][field] = messages[key];
              break; // * Capture only first error
            }
          }
        }
      }
    }
  }

  onSubmit() {
    this.detail = this.detailForm.value;
    if (this.latitude && this.longitude) {
      this.detail["latitude"] = this.latitude;
      this.detail["longitude"] = this.longitude;
    }
    this.dataservice.changeValue(this.detail);
    this.router.navigateByUrl("/confirm");
  }

  fetchGeoLocation() {
    this.geoService.getCurrentLocation().subscribe(
      (coords) => {
        this.latitude = coords.latitude;
        this.longitude = coords.longitude;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  fillUserRow() {
    let userRow = [];
    this.dataservice_data.user.forEach((val) => {
      this.formErrors.user.push({ emailid: "", mobileno: "" });
      userRow.push(
        this.fb.group({
          name: [val.name || "", Validators.required],
          mobileno: [
            val.mobileno || "",
            [
              Validators.required,
              Validators.pattern("^[0-9]*$"),
              Validators.minLength(10),
              Validators.maxLength(10),
            ],
          ],
          emailid: [
            val.emailid || "",
            [Validators.required, Validators.pattern(this.email_regex)],
          ],
        })
      );
    });
    return userRow;
  }

  initUserRow() {
    let userRow = this.fb.group({
      name: ["", Validators.required],
      mobileno: [
        this.dataservice.getMobileno() || "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      emailid: [
        "",
        [Validators.required, Validators.pattern(this.email_regex)],
      ],
    });
    return userRow;
  }

  get formArr() {
    return this.detailForm.get("user") as FormArray;
  }

  addUserRow() {
    this.flag = true;
    this.formErrors.user.push({ emailid: "", mobileno: "" });
    return this.formArr.push(this.initUserRow());
  }

  deleteUserRow(index: number) {
    this.formErrors.user.pop();
    return this.formArr.removeAt(index);
  }

  checkTimeSlot(slot: string) {
    const selectedDate = new Date(this.detailForm.value["date"]);
    const today = new Date();
    const hour = today.getHours();
    const tomorrow = new Date();

    if (this.Cities72HourRule.includes(this.dataservice_data.city)) {
      tomorrow.setDate(tomorrow.getDate() + 3);
    } else {
      tomorrow.setDate(tomorrow.getDate() + 1);
    }

    // * IF ONLY SELECTED DATE == TOMORROW
    if (
      selectedDate.getDate() == tomorrow.getDate() &&
      selectedDate.getMonth() == tomorrow.getMonth()
    ) {
      if (hour < 12) {
        // * All enabled
        return false;
      } else if (
        hour < 15 &&
        (slot == "12 PM - 3 PM" || slot == "3 PM - 6 PM")
      ) {
        // * Last 2 slot
        return false;
      } else if (hour < 18 && slot == "3 PM - 6 PM") {
        // * Last slot
        return false;
      } else {
        // * All disabled
        return true;
      }
    } else {
      return false;
    }
  }

  onTimeSlotChange(slot: string) {
    const today = new Date();
    const hour = today.getHours();

    // * IF DATE IS NOT SELECTED BUT TIME IS SELECTED FIRST
    if (slot) {
      // ! Checks if location is `Cities72HourRule`
      if (this.Cities72HourRule.includes(this.dataservice_data.city)) {
        if (hour < 12) {
          // * All enabled
          this.tomorrow.setDate(this.today.getDate() + 3);
        } else if (
          hour < 15 &&
          (slot == "12 PM - 3 PM" || slot == "3 PM - 6 PM")
        ) {
          // * Last 2 slot
          this.tomorrow.setDate(this.today.getDate() + 3);
        } else if (hour < 18 && slot == "3 PM - 6 PM") {
          // * Last slot
          this.tomorrow.setDate(this.today.getDate() + 3);
        } else {
          this.tomorrow.setDate(this.today.getDate() + 4);
        }
      } else {
        if (hour < 12) {
          // * All enabled
          this.tomorrow.setDate(this.today.getDate() + 1);
        } else if (
          hour < 15 &&
          (slot == "12 PM - 3 PM" || slot == "3 PM - 6 PM")
        ) {
          // * Last 2 slot
          this.tomorrow.setDate(this.today.getDate() + 1);
        } else if (hour < 18 && slot == "3 PM - 6 PM") {
          // * Last slot
          this.tomorrow.setDate(this.today.getDate() + 1);
        } else {
          this.tomorrow.setDate(this.today.getDate() + 2);
        }
      }
    }
  }
}
