import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AdminService } from '../services/adminService';

@Component({
  selector: 'app-editcoupondialog',
  templateUrl: './editcoupondialog.component.html',
  styleUrls: ['./editcoupondialog.component.css']
})
export class EditcoupondialogComponent implements OnInit {
  editcouponForm: FormGroup;
  enabled= true;
  submitted = false;
  error = '';
  role = '';
  errorMessage: string;
  err:boolean;
  private couponcode: string;
  private couponpercentage: string;
  private couponcodedescription: string;
  private singlescreening:boolean;
  private multiplescreening:boolean;

  constructor(private formBuilder: FormBuilder,public dialogRef: MatDialogRef<EditcoupondialogComponent>,
    private adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 

    }
  ngOnInit() {
this.couponcode = this.data.Coupon_code;
this.couponpercentage = this.data.Coupon_discount;
this.couponcodedescription = this.data.Coupon_message;
this.singlescreening = this.data.single_screening_validity;
this.multiplescreening = this.data.multiple_screening_validity;
 
    this.editcouponForm = this.formBuilder.group({
      couponcode: ['', Validators.required],
      couponpercentage: ['', Validators.required],
      couponcodedescription: ['', Validators.required],
      singlescreening: ['',Validators.required],
      multiplescreening: ['', Validators.required]
  });
  
  }

  numericOnly(event): boolean { // restrict e,+,-,E characters in  input type number
    debugger
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    return true;

  }

  valuechangecouponcode(event) {
    this.couponcode = event.target.value
  }
  valuechangecouponpercentage(event) {
    this.couponpercentage = event.target.value;
  }
  valuechangecouponcodedescription(event) {
    this.couponcodedescription = event.target.value;
    }
  changed(evt) {
    this.singlescreening = evt.target.checked;
  }
  change(evt) {
    this.multiplescreening = evt.target.checked;
  }


    reset(){
      this.editcouponForm.reset();
    }

onNoClick(): void {
this.dialogRef.close();
}

save(): void {
  if (this.couponcode && this.couponpercentage && this.couponcodedescription) {
    this.adminService
      .editCoupon({Coupon_code: this.couponcode, Coupon_discount: Number(this.couponpercentage), Coupon_message:this.couponcodedescription, single_screening_validity: this.singlescreening, multiple_screening_validity: this.multiplescreening ,id:this.data.id})
      .subscribe(
        res => {
          this.dialogRef.close();
          window.location.reload();
        },
        err => {
          console.log(err.error);
          err = err.error;
          if (err.success === false) {
            console.log(err);
          }
        }
      );
  } else {
    console.log("select both")
  }
  }

}
