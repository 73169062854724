import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  constructor() {}

  eventEmitter(
    eventName: string,
    eventCategory: string,
    eventLabel: string,
    eventAction: string
    // eventValue: number = null
  ) {
    gtag('event', eventName, {
      eventCategory,
      eventLabel,
      eventAction,
      eventValue: ''
    });
  }
}

// Event Name => can be page => home_page_visit
// Event Category => type of interaction => engagement
// Event Label =>
// Event Action =>
// Event Value =>
