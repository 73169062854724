import { Component, ChangeDetectorRef, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MatRadioChange } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { AnalyticsService } from '../services/analyticsService';
import { AuthService } from '../services/authService';
import { BookingService } from '../services/bookingService';
import { DataService } from '../services/detailsService';
import { ConfirmdialogComponent } from '../confirmdialog/confirmdialog.component';
import { AdminService } from "../services/adminService";
import { MatTableDataSource} from '@angular/material';



export interface PaymentData {
  question: string;
  info: number;
  currency: boolean;
  screeningCost: boolean;
}

declare var Razorpay: any;


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  paymentForm: FormGroup;
  error: string; 
  errorMessage: boolean = false;
  detail: any;
  displayedColumns: string[];
  paymentData: PaymentData[];
  rzp: any;
  razorPayData: any;
  razorPayLaterData: any;
  razorPayRes: any;
  bookingid: string;
  paid: boolean;
  payType: string;
  // TODO: Fetch price from API
  scrPrice: number;
  originalPrice: number;
  originalMessage: string;
  short_url:string;
  discount1: number;
  discount: number;
  finalScr: number;
  discountName: string;
  totVal: number = 2500;
  phoneno: number;
  flag:boolean;
  readOnly:boolean;
  enablePayNow:boolean;
  paylater:boolean;
  freeScreen: boolean;
  couponcode: string = 'false';
  isFullDisc: boolean = false;
  totalPrices: any = {
    first: 0,
    other: 0
  };
  mulScrSource: MatTableDataSource<any>;
  defaultMultipleScreening: number;
  mulScrDiscount:number;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private adminService: AdminService,
    private bookingService: BookingService,
    private dataService: DataService,
    private router: Router,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private ngZone: NgZone,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.enablePayNow=true;
    this.paylater = false;
    this.paid = false;
    this.flag=true;
    this.freeScreen = false;
    this.payType = 'paynow';
    this.readOnly=false;
    this.adminService.getmulipleScrDiscount().subscribe(
      res => {
        if (res.success === false) {
          console.log("empty")
        } else {
          this.mulScrSource = new MatTableDataSource(res);
          this.defaultMultipleScreening = Number(this.mulScrSource.filteredData[0].percentage);
          this.mulScrDiscount = 1 - (this.defaultMultipleScreening/100);
          console.log(this.mulScrDiscount,"mulScr")
          this.authService.checkLogIn().subscribe(
            res => {
              this.discount = res.original_discount;
              this.originalPrice = res.original_cost;
              this.originalMessage = res.original_message;
              this.scrPrice = Number(
                (this.originalPrice * ((100 - this.discount) / 100)).toFixed()
              );
              this.finalScr = this.scrPrice;
              this.totalPrices.first = Number(
                (this.originalPrice * ((100 - this.discount) / 100)).toFixed()
              );
              console.log(this.scrPrice, this.mulScrDiscount, this.scrPrice * this.mulScrDiscount, (this.scrPrice * this.mulScrDiscount).toFixed(), Number((this.scrPrice * this.mulScrDiscount).toFixed()), "the other")
              this.totalPrices.other = Number((this.scrPrice * this.mulScrDiscount).toFixed());
              this.phoneno = res.mobileno;
              console.log(this.discount)
              this.detail = this.dataService.getValue();
      
              if (!this.detail.user) {
                this.router.navigateByUrl('/confirm');
              }
              if (this.detail.user) {
                this.totVal =
                  this.finalScr +
                  this.totalPrices.other * (this.detail.user.length - 1);
                this.displayedColumns = ['question', 'info'];
                this.paymentData = [
                  {
                    question: 'Total screenings booked',
                    info: this.detail.user.length,
                    currency: false,
                    screeningCost: false
                  },
                  {
                    question: 'Screening cost',
                    info: this.scrPrice,
                    currency: true,
                    screeningCost: true
                  },
                  {
                    question: 'coupon',
                    info: this.totalPrices.other,
                    currency: false,
                    screeningCost: false
                  },
                  {
                    question: 'Monthly Discount',
                    info: this.discount,
                    currency: false,
                    screeningCost: false
                  },
                  {
                    question: 'Total',
                    info: this.scrPrice + this.totalPrices.other * (this.detail.user.length - 1),
                    currency: true,
                    screeningCost: false
                  }
                ];
                console.log(this.scrPrice, this.totalPrices.other,this.paymentData[4],"payment")
                this.bookingid = this.detail.bookingid;
              }
              this.createForm();
            },
            err => console.log(err)
          );
        }
      },
      err => console.log(err)
    );
  }

  razorPayOptions = {
    key: '',
    order_id: '',
    name: 'Home Screening',
    theme: { color: '#eb008f' },
    prefill: {},
    image: '/assets/images/niramai-logo.png',
    amount: 200,
    currency: 'INR',
    description: 'Niramai Health Analytix',
    handler: response => {
      response = {
        ...response,
        status: 'RPayStatusConfirmed',
        bookingid: this.bookingid,
        coupon_used: this.couponcode ? this.couponcode : ''
      };

      this.ngZone.run(() => {
        // TODO: spinner
        this.spinner.show();
        this.bookingService.sendPaymentStatus(response).subscribe(
          res => {
            this.spinner.hide();
            this.paid = true;
            this.openDialog(false);
            this.ref.detectChanges();
          },
          err => {
            this.spinner.hide();
            console.log(err);
            this.ref.detectChanges();
          }
        );
      });
    },
    modal: { escape: false, ondismiss: this.razorPayCloseHandler.bind(this) }
  };
close(){
  this.errorMessage = false;
}
  createForm() {
    this.paymentForm = this.fb.group({
      // payTypeSelected: [
      // false,
      // (payTypeSelected) =>
      // !payTypeSelected.value ? { required: true } : false,
      // ],
    });
  }

  onPurchase() {
    var couponData = {
      phoneno: this.phoneno,
      coupon_code: this.couponcode,
      date: new Date()
    };
    if (this.couponcode != 'false') {
      this.bookingService.addCoupon(couponData).subscribe(
        res => {},
        err => console.log(err)
      );
    }
    this.razorPayData = {
      name: this.detail.user[0].name,
      email: this.detail.user[0].emailid,
      mobileno: Number(this.detail.user[0].mobileno),
      bookingid: this.bookingid,
      totalcost: this.totVal,
      totalscreenings: this.detail.user.length,
      screeningcost: this.finalScr
    };

    this.bookingService.sendOrderDetail(this.razorPayData).subscribe(
      res => {
        this.razorPayOptions.key = res['key'];
        this.razorPayOptions.amount = res['data']['amount'];
        this.razorPayOptions.order_id = res['data']['id'];
        this.razorPayOptions.prefill = {
          name: this.razorPayData.name,
          email: this.razorPayData.email,
          contact: this.razorPayData.mobileno
        };

        this.rzp = new Razorpay(this.razorPayOptions);
        this.rzp.open();

        this.rzp.on('payment.failed', error => {
          error = error.error;

          // TODO: spinner
          this.spinner.show();
          this.bookingService
            .sendPaymentStatus({
              bookingid: this.bookingid,
              code: error.code,
              status: 'RPayStatusFailed',
              ...error.metadata
            })
            .subscribe(
              res => {
                this.spinner.hide();
                this.ref.detectChanges();
              },
              err => {
                this.spinner.hide();
                console.log(err);
                this.ref.detectChanges();
              }
            );
        });
      },
      err => console.log(err)
    );
  }

  razorPayCloseHandler() {
    this.ref.detectChanges();
  }

  onPayTypeChange1(event: MatRadioChange) {
    document.getElementById('paybut1').style.backgroundColor = 'pink';
    document.getElementById('paybut2').style.backgroundColor = '#80808036';
    this.payType = event.value;
    this.flag = true;
  }

  onPayTypeChange2(event: MatRadioChange) {
    document.getElementById('paybut2').style.backgroundColor = 'pink';
    document.getElementById('paybut1').style.backgroundColor = '#80808036';
    var payStatus = 'PayLater';
    this.payType = event.value;
    this.flag = true;
    this.razorPayLaterData = {
      bookingid: this.bookingid,
      screeningcost: Number(this.totVal),
      totalcost: Number(this.totVal),
      totalscreenings: Number(this.detail.user.length),
      name: this.detail.user[0].name,
      email: this.detail.user[0].emailid,
      mobileno: Number(this.detail.user[0].mobileno)
    };
    this.bookingService.sendPaymentLink(this.razorPayLaterData).subscribe(
      res => {},
      err => console.log(err)
    );
  }
  isShow = false;
  

  removeCoup(): void{
    var coupbutton = document.getElementById('coupbutton')
    var coupbutton1 = document.getElementById('coupbutton1')
    var element = coupbutton?coupbutton.className:null;
    var element1 = coupbutton1?coupbutton1.className:null;
    var coup;
    var coup1;
    console.log(this.readOnly,"readOnly1")
    if(!document.getElementById('coupvalue')){
      coup = null 
      console.log(coup)

    }
    else{
      coup = document.getElementById('coupvalue').textContent
      console.log(coup)
     
    }
    if(!document.getElementById('coupvalue1')){
      coup1 = null
      console.log(coup1)

    }
    else{
      coup1 = document.getElementById('coupvalue1').textContent
      console.log(coup1)
    }
   
    console.log(coup,coup1,"coupon")
    if((element =='fa fa-plus') && (coup!=null)){
      if(document.getElementById('error').innerHTML!=''){
        this.readOnly = false;
        console.log(this.readOnly,"error")
      }
      else{
      this.readOnly = true;
      }
      console.log(this.readOnly,coup.length,"readOnly2")
    }
    if((element1 =='fa fa-plus') && (coup1 != null)){
      if(document.getElementById('error1').innerHTML!=''){
        this.readOnly = false;
        console.log(this.readOnly,"error1")
      }
      else{
      this.readOnly = true;
      }
      console.log(this.readOnly,"readOnly3")
    }
   var paybut1 =  document.getElementById('paybut1')
   var paybut2 =  document.getElementById('paybut2')
    if(element =='fa fa-close'){
      this.readOnly = false;
      paybut1.style.backgroundColor = 'pink';
      paybut2.style.backgroundColor = '#80808036';
      this.payType= 'paynow';
      this.paylater = false;
      this.enablePayNow=true;
      this.ref.detectChanges();
      console.log(this.payType,"in remove coup");
    } // non 100% coupon 

    if(element1=='fa fa-close'){
      this.readOnly = false;
      paybut1.style.backgroundColor = 'pink';
      paybut2.style.backgroundColor = '#80808036';
      this.payType= 'paynow';
      this.paylater=false;
      this.enablePayNow=true;
      this.ref.detectChanges();
    }
  }
 


  couponFunc1() {
    var coupon_code = (<HTMLInputElement>document.getElementById('coupvalue1'))
      .value;
    if (coupon_code == '') {
      document.getElementById('error1').innerHTML =
        "<i class='fa fa-exclamation-circle'></i> Enter value";
        this.readOnly = false;
    } else {

      this.bookingService.getCoupons(coupon_code.toString()).subscribe(
        res => {
          this.discount1 = res.Coupon_discount;
          this.discountName = res.Coupon_message;
          var multipleValidity = res.multiple_screening_validity;
          if (res.Coupon_code) {
            var datasend = {
              mobileno: this.phoneno,
              couponcode: res.Coupon_code
            };
            this.bookingService.checkCoupon(datasend).subscribe(
              res => {
                if (res && !this.isShow) {
                  this.couponcode = 'false';
                  document.getElementById(
                    'discPhrase1'
                  ).innerHTML = this.originalMessage;
                  document.getElementById(
                    'percentVal1'
                  ).innerHTML = this.discount.toString();
                  //document.getElementById("finalVal1").innerHTML=(this.scrPrice).toString();
                  this.finalScr = this.scrPrice;
                  this.totalPrices.first = this.finalScr;
                  this.totalPrices.other = Number(
                    (this.finalScr * this.mulScrDiscount).toFixed()
                  );
                  this.totVal =
                    this.finalScr +
                    this.totalPrices.other * (this.detail.user.length - 1);
                  document.getElementById(
                    'totalVal1'
                  ).innerHTML = this.totVal.toString();
                  document.getElementById('totalamt').innerHTML =
                    "Total - <i class='fa fa-inr' aria-hidden='true'></i><b>" +
                    this.totVal.toString() +
                    '</b>';
                  document.getElementById('error1').innerHTML =
                    "<i class='fa fa-exclamation-circle'></i> Coupon usage limit has been reached";
                    this.readOnly = false;
                  if(document.getElementById('coupbutton')){
                  document.getElementById('coupbutton').className =
                    'fa fa-plus';
                  }
                  this.isShow = false;
                } else {
                  if (this.discount1 && !this.isShow) {
                    if (!multipleValidity) {
                      
                      this.couponcode = 'false';
                      document.getElementById(
                        'discPhrase1'
                      ).innerHTML = this.originalMessage;
                      document.getElementById(
                        'percentVal1'
                      ).innerHTML = this.discount.toString();
                      //document.getElementById("finalVal1").innerHTML=(this.scrPrice).toString();
                      this.finalScr = this.scrPrice;
                      this.totalPrices.first = this.finalScr;
                      this.totalPrices.other = Number(
                        (this.finalScr * this.mulScrDiscount).toFixed()
                      );
                      this.totVal =
                        this.finalScr +
                        this.totalPrices.other * (this.detail.user.length - 1);
                      document.getElementById(
                        'totalVal1'
                      ).innerHTML = this.totVal.toString();
                      document.getElementById('totalamt').innerHTML =
                        "Total - <i class='fa fa-inr' aria-hidden='true'></i><b>" +
                        this.totVal.toString() +
                        '</b>';
                      document.getElementById('error1').innerHTML =
                        "<i class='fa fa-exclamation-circle'></i> This coupon code is not applicable for multiple screening booking ";
                        this.readOnly = false;
                        if(document.getElementById('coupbutton')){
                          document.getElementById('coupbutton').className =
                            'fa fa-plus';
                          }
                      this.isShow = false;
                     
                    } else {
                      this.couponcode = coupon_code;
                      //document.getElementById("error").innerHTML="<i class='fa fa-check-circle'></i> Applied";
                      document.getElementById('coupbutton1').className =
                        'fa fa-close';
                        
                        
                      document.getElementById(
                        'discPhrase1'
                      ).innerHTML = this.discountName;
                      document.getElementById(
                        'percentVal1'
                      ).innerHTML = this.discount1.toString();
                      document.getElementById('error1').innerHTML = '';
                      this.finalScr = Number(
                        (
                          this.originalPrice *
                          ((100 - this.discount1) / 100)
                        ).toFixed()
                      );
                      this.totalPrices.first = this.finalScr;
                      this.totalPrices.other = Number(
                        (this.finalScr * this.mulScrDiscount).toFixed()
                      );
                      this.totVal =
                        this.finalScr +
                        this.totalPrices.other * (this.detail.user.length - 1);
                      //document.getElementById("finalVal1").innerHTML=this.finalScr.toString();
                      document.getElementById(
                        'totalVal1'
                      ).innerHTML = this.totVal.toString();
                      document.getElementById('totalamt').innerHTML =
                        "Total - <i class='fa fa-inr' aria-hidden='true'></i><b>" +
                        this.totVal.toString() +
                        '</b>';
                      this.isShow = true;
                    }
                  } else {
                    if (!this.isShow) {
                      this.couponcode = 'false';
                      document.getElementById(
                        'discPhrase1'
                      ).innerHTML = this.originalMessage;
                      document.getElementById(
                        'percentVal1'
                      ).innerHTML = this.discount.toString();
                      //document.getElementById("finalVal1").innerHTML=(this.scrPrice).toString();
                      this.finalScr = this.scrPrice;
                      this.totalPrices.first = this.finalScr;
                      this.totalPrices.other = Number(
                        (this.finalScr * this.mulScrDiscount).toFixed()
                      );
                      this.totVal =
                        this.finalScr +
                        this.totalPrices.other * (this.detail.user.length - 1);
                      document.getElementById(
                        'totalVal1'
                      ).innerHTML = this.totVal.toString();
                      document.getElementById('totalamt').innerHTML =
                        "Total - <i class='fa fa-inr' aria-hidden='true'></i><b>" +
                        this.totVal.toString() +
                        '</b>';
                      document.getElementById('error1').innerHTML =
                        "<i class='fa fa-exclamation-circle'></i> Invalid Coupon";
                        this.readOnly = false;
                        if(document.getElementById('coupbutton')){
                          document.getElementById('coupbutton').className =
                            'fa fa-plus';
                          }
                      this.isShow = false;
                    } else if (this.isShow) {
                      this.couponcode = 'false';
                      (<HTMLInputElement>(
                        document.getElementById('coupvalue1')
                      )).value = '';
                      document.getElementById(
                        'percentVal1'
                      ).innerHTML = this.discount.toString();
                      document.getElementById(
                        'discPhrase1'
                      ).innerHTML = this.originalMessage;
                      //document.getElementById("finalVal1").innerHTML=(this.scrPrice).toString();
                      this.finalScr = this.scrPrice;
                      this.totalPrices.first = this.finalScr;
                      this.totalPrices.other = Number(
                        (this.finalScr * this.mulScrDiscount).toFixed()
                      );
                      this.totVal =
                        this.finalScr +
                        this.totalPrices.other * (this.detail.user.length - 1);
                      document.getElementById(
                        'totalVal1'
                      ).innerHTML = this.totVal.toString();
                      document.getElementById('error1').innerHTML = '';
                      document.getElementById('totalamt').innerHTML =
                        "Total - <i class='fa fa-inr' aria-hidden='true'></i><b>" +
                        this.totVal.toString() +
                        '</b>';
                      document.getElementById('coupbutton1').className =
                        'fa fa-plus';
                      this.isShow = false;
                    }
                  }
                }
              },
              err => console.log(err)
            );
          }
        },
        err => console.log(err)
      );
   
    }
  
    this.removeCoup()
  }

  


  stoppedTyping(event) {
    if(this.flag){
      if(this.freeScreen){
        this.payType = null;
      }   
    }
    else{
      this.payType = null;
    }
    if(event!= undefined){
    if(event.keyCode === 8 || event.keyCode === 46) {
      // event.preventDefault();
      this.isFullDisc = true;
  }
  else{
    this.isFullDisc = false;
  }
}
    console.log(document.getElementById('coupbutton').className,"stoppedtyping");
    if(document.getElementById('coupbutton').className == 'fa fa-close'){
      document.getElementById('coupbutton').className = 'fa fa-close';
    }else{
      document.getElementById('coupbutton').className = 'fa fa-plus';
    }
    // document.getElementById('percentVal').innerHTML = this.discount.toString();
    // document.getElementById(
    //   'discPhrase'
    // ).innerHTML = this.paymentData[3].question;
    // document.getElementById('finalVal').innerHTML = this.scrPrice.toString();
    // this.finalScr = this.scrPrice;
    // this.totVal = this.finalScr * this.detail.user.length;
    // document.getElementById('totalVal').innerHTML = (
    //   this.scrPrice * this.detail.user.length
    // ).toString();
    document.getElementById('error').innerHTML = '';
    // document.getElementById('totalamt').innerHTML =
    //   "Total - <i class='fa fa-inr' aria-hidden='true'></i><b>" +
    //   (this.finalScr * this.detail.user.length).toString() +
    //   '</b>';
    //document.getElementById("coupbutton").className="fa fa-plus";
    this.isShow = false;
  }  

  stoppedTyping1(event) {
    console.log(document.getElementById('coupbutton1').className,"stoppedtyping1");
    if(document.getElementById('coupbutton1').className == 'fa fa-close'){
      document.getElementById('coupbutton1').className = 'fa fa-close';
    }else{
      document.getElementById('coupbutton1').className = 'fa fa-plus';
    }
    document.getElementById('percentVal1').innerHTML = this.discount.toString();
    // document.getElementById(
    //   'discPhrase1'
    // ).innerHTML = this.originalMessage;
    //document.getElementById("finalVal1").innerHTML=(this.scrPrice).toString();
    if(event!= undefined){
      if(event.keyCode === 8 || event.keyCode === 46) {
        // event.preventDefault();
        console.log("disableBackspace")
        this.isFullDisc = true;
    }
    else{
      this.isFullDisc = false;
    }
  }
    this.finalScr = this.scrPrice;
    this.totalPrices.first = this.finalScr;
    this.totalPrices.other = Number((this.finalScr * this.mulScrDiscount).toFixed());
    this.totVal =
      this.finalScr + this.totalPrices.other * (this.detail.user.length - 1);
    // document.getElementById('totalVal1').innerHTML = this.totVal.toString();
    document.getElementById('error1').innerHTML = '';
    // document.getElementById('totalamt').innerHTML =
    //   "Total - <i class='fa fa-inr' aria-hidden='true'></i><b>" +
    //   this.totVal.toString() +
    //   '</b>';
    //document.getElementById("coupbutton1").className="fa fa-plus";
    this.isShow = false;
  }

  couponFunc() {
    var coupon_code = (<HTMLInputElement>document.getElementById('coupvalue'))
      .value;
    if (coupon_code == '') {
      document.getElementById('error').innerHTML =
        "<i class='fa fa-exclamation-circle'></i> Enter value";
    } else {
      this.bookingService.getCoupons(coupon_code.toString()).subscribe(
        res => {
          this.discount1 = res.Coupon_discount;
          this.discountName = res.Coupon_message;
          if (res.Coupon_code) {
            var datasend = {
              mobileno: this.phoneno,
              couponcode: res.Coupon_code
            };
            this.bookingService.checkCoupon(datasend).subscribe(
              res => {
                if (res && !this.isShow) {
                  if(this.flag){
                    console.log(this.payType, this.freeScreen)
                    if(this.freeScreen){
                      this.payType = null;
                    }
                  }
                  else{
                   
                    this.payType = null;
                  }
                  this.isFullDisc = false;
                  this.couponcode = 'false';
                  document.getElementById(
                    'discPhrase'
                  ).innerHTML = this.originalMessage;
                  document.getElementById(
                    'percentVal'
                  ).innerHTML = this.discount.toString();
                  document.getElementById(
                    'finalVal'
                  ).innerHTML = this.scrPrice.toString();
                  this.finalScr = this.scrPrice;
                  this.totVal = this.finalScr * this.detail.user.length;
                  document.getElementById('totalVal').innerHTML = (
                    this.scrPrice * this.detail.user.length
                  ).toString();
                  document.getElementById('totalamt').innerHTML =
                    "Total - <i class='fa fa-inr' aria-hidden='true'></i><b>" +
                    (this.finalScr * this.detail.user.length).toString() +
                    '</b>';
                  document.getElementById('error').innerHTML =
                    "<i class='fa fa-exclamation-circle'></i> Coupon usage limit has been reached";
                    this.readOnly = false;
                  document.getElementById('coupbutton').className =
                    'fa fa-plus';
                  this.isShow = false;
                } else {
                  if (this.discount1 && !this.isShow) {
                    if (this.discount1 == 100) {
                      this.payType = 'paylater';
                      this.isFullDisc = true;
                    }
                    console.log(this.payType,this.flag,"paytype check");
                    this.couponcode = coupon_code;
                    //document.getElementById("error").innerHTML="<i class='fa fa-check-circle'></i> Applied";
                    document.getElementById('coupbutton').className =
                      'fa fa-close';
                    //   var click = function(){
                    //     console.log("click");
                    //     this.payType =null;
                    //     console.log(this.payType,"paytype");
                    //     document.getElementById('btn1').setAttribute("disabled","disabled");

                    //   }
                    // document.querySelector('.fa-close').addEventListener("click", click)
                    // console.log(this.payType,"paytype check 2");
                    document.getElementById(
                      'discPhrase'
                    ).innerHTML = this.discountName;
                    document.getElementById(
                      'percentVal'
                    ).innerHTML = this.discount1.toString();
                    document.getElementById('error').innerHTML = '';
                    this.finalScr = Number(
                      (
                        this.originalPrice *
                        ((100 - this.discount1) / 100)
                      ).toFixed()
                    );
                    this.totVal = this.finalScr * this.detail.user.length;
                    document.getElementById(
                      'finalVal'
                    ).innerHTML = this.finalScr.toString();
                    document.getElementById('totalVal').innerHTML = (
                      this.finalScr * this.detail.user.length
                    ).toString();
                    document.getElementById('totalamt').innerHTML =
                      "Total - <i class='fa fa-inr' aria-hidden='true'></i><b>" +
                      (this.finalScr * this.detail.user.length).toString() +
                      '</b>';
                    this.isShow = true;
                  } else {
                    if (!this.isShow) {
                      if(this.flag){
                        console.log(this.payType, this.freeScreen)
                        if(this.freeScreen){
                          this.payType = null;
                        }
                      }
                      else{
                        this.payType = null;
                      }
                      this.isFullDisc = false;
                      this.couponcode = 'false';
                      document.getElementById(
                        'discPhrase'
                      ).innerHTML = this.originalMessage;
                      document.getElementById(
                        'percentVal'
                      ).innerHTML = this.discount.toString();
                      document.getElementById(
                        'finalVal'
                      ).innerHTML = this.scrPrice.toString();
                      this.finalScr = this.scrPrice;
                      this.totVal = this.finalScr * this.detail.user.length;
                      document.getElementById('totalVal').innerHTML = (
                        this.scrPrice * this.detail.user.length
                      ).toString();
                      document.getElementById('totalamt').innerHTML =
                        "Total - <i class='fa fa-inr' aria-hidden='true'></i><b>" +
                        (this.finalScr * this.detail.user.length).toString() +
                        '</b>';
                      document.getElementById('error').innerHTML =
                        "<i class='fa fa-exclamation-circle'></i> Invalid Coupon";
                        this.readOnly = false;
                      document.getElementById('coupbutton').className =
                        'fa fa-plus';
                      this.isShow = false;
                    } else if (this.isShow) {
                      if(this.flag){
                        console.log(this.payType, this.freeScreen)
                        if(this.freeScreen){
                          this.payType = null;
                        }   
                      }
                      else{
                        this.payType = null;
                      }
                      this.isFullDisc = false;
                      this.couponcode = 'false';
                      (<HTMLInputElement>(
                        document.getElementById('coupvalue')
                      )).value = '';
                      document.getElementById(
                        'percentVal'
                      ).innerHTML = this.discount.toString();
                      document.getElementById(
                        'discPhrase'
                      ).innerHTML = this.originalMessage;
                      document.getElementById(
                        'finalVal'
                      ).innerHTML = this.scrPrice.toString();
                      this.finalScr = this.scrPrice;
                      this.totVal = this.finalScr * this.detail.user.length;
                      document.getElementById('totalVal').innerHTML = (
                        this.scrPrice * this.detail.user.length
                      ).toString();
                      document.getElementById('error').innerHTML = '';
                      document.getElementById('totalamt').innerHTML =
                        "Total - <i class='fa fa-inr' aria-hidden='true'></i><b>" +
                        (this.finalScr * this.detail.user.length).toString() +
                        '</b>';
                      document.getElementById('coupbutton').className =
                        'fa fa-plus';
                      this.isShow = false;
                    }
                  }
                }
              },
              err => console.log(err)
            );
          }
        },
        err => console.log(err)
      );
    }
    this.removeCoup()
  
  }
  
  onSubmit() {
    setTimeout( ()=>{
    var payStatus = 'PayLater';
    if (this.payType === 'paylater') {
      this.razorPayLaterData = {
        bookingid: this.bookingid,
        screeningcost: Number(this.totVal),
        totalcost: Number(this.totVal),
        totalscreenings: Number(this.detail.user.length),
        name: this.detail.user[0].name,
        email: this.detail.user[0].emailid,
        mobileno: Number(this.detail.user[0].mobileno)
      };

      if (this.totVal === 0) {
        payStatus = 'FreeScreening';
        this.freeScreen = true;
      }
      var couponData = {
        phoneno: this.phoneno,
        coupon_code: this.couponcode,
        date: new Date()
      };
      if (this.couponcode != 'false') {
        this.bookingService.addCoupon(couponData).subscribe(
          res => {
            // TODO: spinner
            this.spinner.show();
            this.bookingService
              .sendPaymentStatus({
                bookingid: this.bookingid,
                status: payStatus,
                totalcost: this.totVal,
                totalscreenings: this.detail.user.length,
                screeningcost: this.finalScr,
                coupon_used: this.couponcode
              })
              .subscribe(
                res => {
                  this.spinner.hide();
                  this.openDialog(true);
                  this.ref.detectChanges();
                },
                err => {
                  this.spinner.hide();
                  console.log(err);
                  this.error = err;
                  this.errorMessage = true;
                  this.ref.detectChanges();
                }
              );
          },
          err => console.log(err)
        );
      } else {
        // TODO: spinner
        this.spinner.show();
        this.bookingService
          .sendPaymentStatus({
            bookingid: this.bookingid,
            status: payStatus,
            totalcost: this.totVal,
            totalscreenings: this.detail.user.length,
            screeningcost: this.finalScr
          })
          .subscribe(
            res => {
              this.spinner.hide();
              this.openDialog(true);
              this.ref.detectChanges();
            },
            err => {
              this.spinner.hide();
              this.error = err;
              this.errorMessage = true;
              console.log(err);
              this.ref.detectChanges();
            }
          );
      }
    }
  }, 5000)
  }

  openDialog(isPayLater: boolean) {
    this.dataService.resetValue();
    this.dialog.open(ConfirmdialogComponent, {
      data: { isPayLater, scrPrice: this.totVal },
      width: '375px',
      height: isPayLater ? '570px' : '540px',
      panelClass: 'mat-confirm-dialog',
      disableClose: true
    });
  }
}
