import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DataService {
  constructor() {}

  private detailData: any = {};
  private mobileno: any;

  changeValue(value) {
    this.detailData = { ...this.detailData, ...value };
  }

  getValue() {
    return this.detailData;
  }

  changeLocation(city) {
    this.detailData = { city };
  }

  resetValue() {
    this.detailData = {};
  }

  changeBookingid(id) {
    this.detailData = { ...this.detailData, bookingid: id };
  }

  changeMobileno(mobileno) {
    this.mobileno = mobileno;
  }

  getMobileno() {
    return this.mobileno;
  }

  resetMobileno() {
    this.mobileno = null;
  }
}
